import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';
import GroupFilter from './GroupFilter';
import NotificationTypeFilter from './NotificationTypeFilter';
import LessonFilter from './LessonFilter';
import SortFilter from './SortFilter';
import FeedbackList from './FeedbackList';

import styles from '../styles/NotificationPortal.module.css';

const filterFeedbacks = (feedbacks, selectedGroup, selectedLesson) => {
  return feedbacks.filter((feedback) => {
    const matchesGroup = !selectedGroup || feedback.groupId.name === selectedGroup;
    const matchesLesson = !selectedLesson || feedback.lessonId.name === selectedLesson;
    return matchesGroup && matchesLesson;
  });
};

const sortFeedbacks = (feedbacks, sortOrder) => {
  return feedbacks.sort((a, b) =>
    sortOrder === 'newest'
      ? new Date(b.createdAt) - new Date(a.createdAt)
      : new Date(a.createdAt) - new Date(b.createdAt)
  );
};

const Feedbacks = () => {
  const { jwt } = useApplicationContext();
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');
  const [filterType, setFilterType] = useState('unresolved');
  const [sortOrder, setSortOrder] = useState('newest');
  const [feedbacks, setFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SWC_API_URL}/feedback`, {
          headers: { 'x-access-token': jwt },
        });
        const data = await response.json();
        setFeedbacks(data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchFeedbacks();
  }, [jwt]);

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    return <Typography variant="h6" color="error">Failed to load feedbacks.</Typography>;
  }

  const groupIds = [...new Set(feedbacks.map((feedback) => feedback.groupId.name))];
  const lessonIds = [...new Set(feedbacks.map((feedback) => feedback.lessonId.name))];

  const filteredFeedbacks = filterFeedbacks(feedbacks, selectedGroup, selectedLesson);
  const sortedFeedbacks = sortFeedbacks(filteredFeedbacks, sortOrder);

  const unresolvedFeedbacks = sortedFeedbacks.filter(feedback => !feedback.resolvedTs);
  const resolvedFeedbacks = sortedFeedbacks.filter(feedback => feedback.resolvedTs);

  return (
    <Container className={styles.container}>
      <Typography variant="h4" sx={{ fontWeight: 600, fontSize: '2rem' }} gutterBottom>
        Feedbacks
      </Typography>
      <GroupFilter selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} groupIds={groupIds} />
      <LessonFilter selectedLesson={selectedLesson} setSelectedLesson={setSelectedLesson} lessonIds={lessonIds} />
      <NotificationTypeFilter messageType="feedback" filterType={filterType} setFilterType={setFilterType} />
      <SortFilter sortOrder={sortOrder} setSortOrder={setSortOrder} />
      <FeedbackList feedbacks={filterType === 'unresolved' ? unresolvedFeedbacks : resolvedFeedbacks} filterType={filterType} />
    </Container>
  );
};

export default Feedbacks;