export const groupsDoodle = `
@grid: 50x1 / 100vmax;

@place: center right;
@size: calc(40% / @I * @i);

transform: rotate(calc(@i * 45deg));

top: 75%;
border-radius: 100%;
border: 1px solid hsla(
  calc(10 + 4 * @i), 70%, 68%, @r.8
);

@media (max-width: 768px) {
  transform: rotate(calc(@i * 45deg));
}
`;

export const loginDoodle = `
:doodle {
  @grid: 2 / 100vmax
  grid-gap: 2vmax;
  display: flex;
  justify-content: center;
  max-width: 1200px;
}

  /* Randomly generate shades of green */
  background: hsla(
    @r(100, 180),       /* Hue: Random value between 100 and 140 degrees (green range) */
    @r(50%, 80%),      /* Saturation: Random value from 50% to 100% */
    @r(40%, 70%),       /* Lightness: Random value from 40% to 80% */
    @r(.6, .8)           /* Alpha: Random value from 0.6 to 1 (semi-transparent to opaque) */
  );
  
  /* Transformations for dynamic and engaging effects */
  transform: 
    rotate(@r(0deg, 360deg))             /* Random rotation */
    scale(@rand(.4, .6))                /* Random scale factor */
    translate3d(@r(-100px, 100px), @r(-100px, 100px), 0);  /* Random position within a range */

  /* Additional styles to enhance the appearance */
  border-radius: @r(20px, 100px);       /* Random border radius for varied shapes */
  box-shadow: 0 0 @r(5px, 20px) hsla(0, 0%, 0%, 0.2); /* Random shadow for depth effect */
  

  @media (min-width: 1200px) {
    @grid: 10 / 1200px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;  /* Center the grid */
    grid-gap: 5%;
  }
`;

export const resetPasswordDoodle = `
    @grid: 1 / 100vw;
    @content: @svg(
    viewBox: 0 16 (20 / (1 / 100vw)) (20 / (1 / 100vh));
    stroke: #fff;
    stroke-opacity: .1;
    stroke-width: .04;
    stroke-linecap: round;
    line*32x32 {
      draw: @r(2s);
      x1, y1, x2, y2: @p(
      @nx(-1) @ny(-1) @nx @ny,
      @nx @ny(-1) @nx(-1) @ny,
      @nx @ny(-1) @nx @ny
      );
    }
    );`;

export const heroDoodle = `
@grid: 1x60 / 100vmax;
@size: 75.8% 1px;
justify-self: center;
background: #0089A3;
opacity: 0.15;
transform:
rotate(-15deg)
translateX(calc(@sin(@i / 4) * π * 10px));
  `;
