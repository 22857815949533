import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { profanity } from '@2toad/profanity';

import styles from '../styles/Comments.module.css';
import { formatCode } from '../utils/codeFormatter';
import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';

const LANGUAGES = ['html', 'css', 'javascript'];

function AddCommentForm({ updateComment }) {
  let { groupId, lessonId } = useParams();

  const [text, setText] = useState('');
  const [code, setCode] = useState('');
  const [formatErrors, setFormatErrros] = useState('');
  const [focused, setFocused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [language, setLanguage] = useState(LANGUAGES[0]);
  const textAreaRef = useRef(null);

  const { jwt, setToast} = useApplicationContext();

  async function postComment(e) {
    e.preventDefault();

    if (text === '' && code === '') {
      return;
    }

    const newCommentModel = {
      isReply: false,
      isDeleted: false,
      lessonId: lessonId,
      groupId: groupId,
      content: [],
    };
    if (text !== '') {
      // if profanity exists, create a popup like when we go to delete comment, tell user they need to edit their comment or something like that.
      if (profanity.exists(text)) {
        return setToast({
          checkMark: false,
          message: 'Please do not use foul language! Try again!',
          time: 3,
        });
      }
    }

    const blocks = text.split('``');
    for (let block of blocks) {
      const commentContent = {
        type: 'text',
        content: block,
      };

      const regex = new RegExp(`^\`(${LANGUAGES.join('|')})\\n`);
      if (regex.test(block)) {
        const codeBlockStart = regex.exec(block).at(0);
        const blockLanguage = codeBlockStart.replace('`', '').replace('\n', '');

        commentContent.type = 'code';
        commentContent.language = blockLanguage;
        commentContent.content = block.substring(codeBlockStart.length);

        let { error, formatted } = await formatCode(commentContent.content, commentContent.language);

        if (error) {
          console.error('Error while formatting the code');
        } else {
          formatted = formatted
            .split('\n')
            .map((line) => line.trim())
            .join('\n');
          formatted = formatted.replaceAll('\n', '');
        }

        commentContent.content = formatted;
      }

      if (block.startsWith('`\n')) {
        commentContent.content = block.substring(2);
      }

      if (block.endsWith('\n')) {
        commentContent.content = commentContent.content.substring(0, block.length - 1);
      }

      if (block === '`' || block === '`\n' || block === '' || block === '\n') {
        continue;
      }

      newCommentModel.content.push(commentContent);
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({
        newComment: newCommentModel,
      }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/comment`, requestOptions)
      .then((res) => {
        if (res.status === 201) {
          textAreaRef.current.style.height = 'auto';
          setText('');
          return res.json();
        } else {
          return;
        }
      })
      .then((data) => updateComment('addTopLevelComment', data))
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChange(e) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    setText(e.target.value);
  }

  function cancel() {
    setText('');
    setFocused(false);
  }

  function handleCodeChange({ target }) {
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
    setCode(target.value);
  }

  function handleLanguageChange({ target }) {
    setLanguage(target.value);
  }

  function handleAddCode() {
    const codeBlock = `\n\`\`\`${language}\n${code}\n\`\`\``;

    setText(text + codeBlock);
    setIsVisible(false);
    setCode('');
    textAreaRef.current.focus();
  }

  function handleAddCodeSnippetForm() {
    setIsVisible(true);
    setFormatErrros('');
  }

  function handleCancelCode() {
    setIsVisible(false);
    setFormatErrros('');
    setCode('');
  }

  async function handleFormatCode() {
    setFormatErrros('');
    let { error, formatted } = await formatCode(code, language);

    if (error) {
      setFormatErrros(error.toString());
    }

    setCode(formatted);
  }

  const codeBlockCallbackRef = (node) => {
    if (node) {
      node.focus();
      node.style.height = 'auto';
      node.style.height = `${node.scrollHeight}px`;
    }
  };

  useEffect(() => {
    textAreaRef.current.style.height = 'inherit';
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
  }, [text]);

  return (
    <form className={styles['add--comment--form']} onSubmit={postComment}>
      <button type="button" className={styles['add-code-button']} onClick={handleAddCodeSnippetForm}>
        Add code snippet
      </button>

      <textarea
        ref={textAreaRef}
        rows="1"
        placeholder="Add a comment..."
        className={styles['text-block-area']}
        onChange={(e) => handleChange(e)}
        value={text}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {isVisible && (
        <div className={styles['code-snippet']}>
          <label>Select language:</label>
          <select onChange={handleLanguageChange}>
            {LANGUAGES.map((language) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </select>
          <textarea
            ref={codeBlockCallbackRef}
            className={styles['code-block']}
            placeholder="Paste your code here"
            value={code}
            onChange={handleCodeChange}
          />
          <button type="button" className={styles['add-code-comment']} onClick={handleAddCode}>
            Add
          </button>
          <button
            type="button"
            className={styles['format-code-button']}
            onClick={handleFormatCode}
            disabled={code === ''}
          >
            Format
          </button>
          <button type="button" className={styles['cancel-code-button']} onClick={handleCancelCode}>
            Cancel
          </button>
          {formatErrors && <span className={styles['code-block-errors']}>{formatErrors}</span>}
        </div>
      )}
      <input
        type="submit"
        value="Comment"
        className={
          focused || text !== ''
            ? text !== '' // Visible if text is not empty, or focused
              ? styles['submit--active'] // Blue when focused and text is not empty
              : styles['submit-temp-inactive'] // Grey when focused and text is empty
            : styles['submit--inactive'] // Invisible when not focused and text is empty
        }
      ></input>
      <input
        type="button"
        value="Cancel"
        className={
          focused || text !== ''
            ? styles['cancel--active'] // Visible when focused or text is not empty
            : styles['cancel--inactive'] // Invisible when not focused and text is empty
        }
        onClick={() => cancel()}
      ></input>
    </form>
  );
}

export default AddCommentForm;
