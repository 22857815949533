import React from 'react';

import { Typography } from '@mui/material';

import { ProjectsMapper } from '../configs/projectsMapper';

import styles from '../styles/StudentProjects.module.css';

const Project = ({ project }) => (
  <div className={styles.studentProject} style={{backgroundColor: project.color}}>
    <img className={styles.studentProjectImage} src={project.image} alt="" />
    <div>
      <Typography className={styles.studentProjectTitle} variant="h5" sx={{ fontWeight: 600 }}>
        {project.title}
      </Typography>
      <Typography className={styles.studentProjectDescription} variant="h6">
        {project.description}
      </Typography>
    </div>
  </div>
);

const StudentProjects = () => (
  <div className={styles.studentProjectsContainer}>
    <Typography className={styles.studentProjectsTitle} variant="h4" sx={{ fontWeight: 600, padding: '1rem' }}>
      Student Showcase
    </Typography>
    <div className={styles.studentProjectsListContainer}>
      {ProjectsMapper.map((project) => (
        <Project project={project} />
      ))}
    </div>
  </div>
);

export default StudentProjects;
