import React from 'react';

import { Typography } from '@mui/material';

import { PARTNER_PENNYAPPEAL } from '../../../constants/images';
import { PARTNER_PS43 } from '../../../constants/images';

import styles from '../styles/Partners.module.css';

const Partners = () => (
  <div className={styles.partnersContainer}>
    <Typography className={styles.partnersTitle} variant="h4" sx={{ fontWeight: 600, padding: '1rem' }}>
      Our Partners
    </Typography>
    <Typography className={styles.partnersText} variant="h6" gutterBottom>
      Our partnerships are at the heart of our mission, enabling us to deliver high-quality, engaging, and interactive
      educational experiences to students across the globe
    </Typography>
    <div className={styles.partnersListContainer}>
      <img src={PARTNER_PENNYAPPEAL} alt="" className={styles.partnerImage} />
      <img src={PARTNER_PS43} alt="" className={styles.partnerImage} />
    </div>
  </div>
);

export default Partners;
