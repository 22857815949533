// src/components/LessonFilter.js
import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import styles from '../styles/NotificationPortal.module.css';

const LessonFilter = ({ selectedLesson, setSelectedLesson, lessonIds }) => {
  return (
    <FormControl fullWidth className={styles.formControl}>
      <InputLabel>Filter by Lesson</InputLabel>
      <Select
        value={selectedLesson}
        onChange={(e) => setSelectedLesson(e.target.value)}
        label="Filter by Lesson"
      >
        <MenuItem value="">
          <em>All Lessons</em>
        </MenuItem>
        {lessonIds.map((lesson) => (
          <MenuItem key={lesson} value={lesson}>
            {lesson}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LessonFilter;
