import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';

import { useApplicationContext } from '../../contexts/ApplicationContextProvider';
import Header from '../shared/header/Header';
import Notifications from './components/Notifications';
import Feedbacks from './components/Feedbacks';

import styles from './styles/NotificationPortal.module.css';

const NotificationPortal = () => {
  const { authLevel } = useApplicationContext();
  const [view, setView] = useState('notifications'); // State to manage current view

  const history = useHistory();

  if (authLevel !== 'swcadmin') {
    history.push('/');
  }

  return (
    <Container className={styles.portalContainer}>
      <Header />
      <Container className={styles.container}>
        <div className={styles.buttonWrapper}>
          <Button className={styles.toggleButtonContainer} variant="contained" color='success' onClick={() => setView(view === 'notifications' ? 'feedbacks' : 'notifications')}>
            {view === 'notifications' ? 'Switch to Feedbacks' : 'Switch to Notifications'}
          </Button>
        </div>
        {view === 'notifications' ? <Notifications /> : <Feedbacks />}
      </Container>
    </Container>
  );
};

export default NotificationPortal;
