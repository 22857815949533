import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Card, CardContent } from '@mui/material';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';
import styles from '../styles/NotificationPortal.module.css';

const Notification = ({ notification }) => { 
  const { jwt } = useApplicationContext();
  const history = useHistory();

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + ' years ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  };

  const handleMarkAsRead = () => {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwt },
      body: JSON.stringify({
        notificationIds: [notification._id],
      }),
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/notification`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          history.push(`/groups/${notification.groupId._id}/${notification.lessonId._id}/${notification.commentId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
  <Card className={styles.listItem} sx={{ marginBottom: 2, borderRadius: 2, boxShadow: 3 }} onClick={handleMarkAsRead}>
    <CardContent>
      <div className={styles.listItemHeader}>
        <div className={styles.listItemName}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, fontSize: '1rem', color: '#005fcc' }}
          >{`${notification.replier.fname} ${notification.replier.lname}`}</Typography>
          <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1rem', whiteSpace: 'pre-wrap' }}>
            {' replied'}
          </Typography>
        </div>
        <Typography variant="body2" color="textSecondary">
          {' '}
          {timeSince(new Date(notification.createdAt))}
        </Typography>
      </div>
      <Typography color="textSecondary" sx={{ fontWeight: 400, fontSize: '1rem' }}>
        {'"' + notification.content + '"'}
      </Typography>
      <div className={styles.listItemFooter}>
        <Typography
          variant="body2"
          color="textSecondary"
        >{`${notification.groupId.name} | ${notification.lessonId.name}`}</Typography>
      </div>
    </CardContent>
  </Card>
)};

export default Notification;
