import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@mui/material';

import Header from '../home/components/Header';
import BackgroundDoodle from '../shared/BackgroundDoodle';
import { loginDoodle } from '../../config/doodleMapper';
import { JoinSiteModalProvider } from '../home/providers/JoinSiteModalProvider';
import { useApplicationContext } from '../../contexts/ApplicationContextProvider';

import styles from './styles/Login.module.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [showPw, setShowPw] = useState(false);

  const emailRef = useRef(null);
  const pwRef = useRef(null);

  const { setJwt, setAuthLevel } = useApplicationContext();

  const history = useHistory();

  const toggleShowPw = () => {
    setShowPw(!showPw);
  };

  function loginCall() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email, password: password }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/auth/login`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          let data = await response.json();

          localStorage.setItem('swc-access-token', JSON.stringify(data.accessToken));
          localStorage.setItem('swc-user-email', JSON.stringify(data.email));
          localStorage.setItem('swc-user-authLevel', JSON.stringify(data.authLevel));
          setJwt(data.accessToken);
          setAuthLevel(data.authLevel);

          history.push('/');
        } else if (response.status === 404 || response.status === 401) {
          setShowError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    loginCall();
  }

  const handleGoBack = () => {
    history.push('/');
  };

  const handleFocus = (e) => {
    if (e.target.id === 'email') {
      emailRef.current.classList.add(styles['focus']);
    }
    if (e.target.id === 'pw') {
      pwRef.current.classList.add(styles['focus']);
    }
  };

  const handleBlur = (e) => {
    if (e.target.id === 'email') {
      emailRef.current.classList.remove(styles['focus']);
    }
    if (e.target.id === 'pw') {
      pwRef.current.classList.remove(styles['focus']);
    }
  };

  return (
    <JoinSiteModalProvider>
      <div className={styles.login}>
        <Header />
        <div className={styles['login-content']}>
        <BackgroundDoodle styles={styles.doodle} doodle={loginDoodle} />
          <form className={styles['form']} onSubmit={handleSubmit}>
            <Typography className={styles.loginTitle} variant="h4">
              Students Who Code
            </Typography>
            <Typography className={styles.loginText} variant="h6" gutterBottom>
              Ready to learn?
            </Typography>
            <input
              id="email"
              ref={emailRef}
              className={styles.email}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <div ref={pwRef} className={styles['pw-holder']}>
              <input
                id="pw"
                type={showPw ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <span onClick={() => toggleShowPw()}>
                {showPw ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
              </span>
            </div>
            {showError && (
              <div className={styles['incorrent']}>Your login details are incorrect . Please try again.</div>
            )}
            <button type="submit" className={styles['login-button']}>
              Login
            </button>
            <button type="button" className={styles['go-back-button']} onClick={handleGoBack}>
              Go Back
            </button>
          </form>
        </div>
      </div>
    </JoinSiteModalProvider>
  );
}

export default Login;
