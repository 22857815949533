import React, { createContext, useContext, useState } from 'react';

const ApplicationContext = createContext(null);
export const useApplicationContext = () => useContext(ApplicationContext);

export const ApplicationContextProvider = ({ children }) => {
  const [jwt, setJwt] = useState(JSON.parse(localStorage.getItem('swc-access-token')));
  const [authLevel, setAuthLevel] = useState(JSON.parse(localStorage.getItem('swc-user-authLevel')));
  const [groups, setGroups] = useState([]);
  const [toast, setToast] = useState(null);

  // Helper function to update the last viewed lesson in a group
  const updateLastLessonViewed = (groupId, lessonId) => {
    if (groups.length !== 0) {
      setGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (group._id === groupId && group.lastLessonViewedId !== lessonId) {
            return { ...group, lastLessonViewedId: lessonId };
          }
          return group;
        })
      );
    }
  };

  // Helper function to update lesson progress (e.g., percentage watched)
  const updateLessonProgress = (groupId, lessonId, progress) => {
    if (groups.length !== 0) {
      setGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (group._id === groupId) {
            const updatedLessons = group.course.lessons.map((lesson) => {
              if (lesson._id === lessonId && lesson.percWatched < progress.percWatched) {
                return {
                  ...lesson,
                  percWatched: progress.percWatched,
                  secondsWatched: progress.secondsWatched,
                };
              }
              return lesson;
            });
            return { ...group, course: { ...group.course, lessons: updatedLessons } };
          }
          return group;
        })
      );
    }
  };

  // Helper function to update quiz progress
const updateQuizProgress = (groupId, lessonId, quizAnswers, quizComplete, quizCompleteTime, score, quizScore, quizDetails) => {
  if (groups.length !== 0) {
    setGroups((prevGroups) =>
      prevGroups.map((group) => {
        if (group._id === groupId) {
          const updatedLessons = group.course.lessons.map((lesson) => {
            if (lesson._id === lessonId) {
              return {
                ...lesson,
                quizAnswers: quizAnswers,
                quizComplete: quizComplete,
                ...(quizComplete ? { quizCompleteTime: quizCompleteTime } : {}),
                score: score > quizScore ? score : quizScore,
                retook: quizDetails.retook,
              };
            }
            return lesson;
          });
          return { ...group, course: { ...group.course, lessons: updatedLessons } };
        }
        return group;
      })
    );
  }
};


  const value = {
    jwt,
    setJwt,
    authLevel,
    setAuthLevel,
    groups,
    setGroups,
    updateLastLessonViewed,
    updateLessonProgress,
    updateQuizProgress,
    toast,
    setToast,
  };

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};
