import React, { useState, useEffect } from 'react';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';

import styles from './styles/Toast.module.css';

const Toast = () => {
  const { toast, setToast } = useApplicationContext();
  const [countdown, setCountdown] = useState(toast.time);
  const [progress, setProgress] = useState('100%');

  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(() => {
        setCountdown((countdown) => countdown - 0.1);
      }, 100);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown > 0) {
      const progress = countdown / toast.time;
      setProgress(`${progress * 100}%`);
    } else {
      setProgress('0%');
    }
  }, [countdown]);

  useEffect(() => {
    if (progress === '0%') {
      setToast(null);
    }
  }, [progress]);

  return (
    <div className={styles['popup']}>
      {toast.checkMark ? (
        <FontAwesomeIcon className={styles.checkmark} icon={faCircleCheck}></FontAwesomeIcon>
      ) : (
        <FontAwesomeIcon className={styles.xmark} icon={faCircleXmark}></FontAwesomeIcon>
      )}
      <span className={styles.message}>{toast.message}</span>
      <div className={styles['countdown-bar']}>
        <div className={styles.progress} style={{ width: progress }}></div>
      </div>
    </div>
  );
};

export default Toast;
