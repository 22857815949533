import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

import styles from '../styles/NotificationPortal.module.css';
import { style } from '@mui/system';

const GroupFilter = ({ selectedGroup, setSelectedGroup, groupIds }) => (
  <FormControl className={styles.formControl} fullWidth variant="outlined" margin="normal">
    <InputLabel className={styles.inputLabel} id="group-filter-label">Filter by Group</InputLabel>
    <Select
      className={style.select}
      labelId="group-filter-label"
      id="group-filter"
      value={selectedGroup}
      onChange={(e) => setSelectedGroup(e.target.value)}
      label="Filter by Group"
    >
      <MenuItem value="">
        <em>All Groups</em>
      </MenuItem>
      {groupIds.map((groupId) => (
        <MenuItem key={groupId} value={groupId}>
          {groupId}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default GroupFilter;
