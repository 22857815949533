import React from 'react';
import { useHistory } from 'react-router-dom';

import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { LinearProgress } from '@mui/material';
import { COURSE_HTML_CSS } from '../../../constants/images';

import styles from '../styles/Group.module.css';

const calculateOverallProgress = (lessons) => {
  const videoLessons = lessons.filter((lesson) => typeof lesson.percWatched === 'number');
  const totalPercWatched = videoLessons.reduce((sum, lesson) => sum + lesson.percWatched, 0);
  const average = videoLessons.length ? totalPercWatched / videoLessons.length : 0;

  return average.toFixed(2);
};

const Group = ({ groupDetail }) => {
  const percentageCompleted = calculateOverallProgress(groupDetail.course.lessons);
  const lesson = groupDetail.lastLessonViewedId ? groupDetail.lastLessonViewedId : groupDetail.course.lessons[0]._id;
  const history = useHistory();

  return (
    <Card className={styles.groupContainer} sx={{ width: 320 }}>
      <AspectRatio minHeight="120px" maxHeight="250px">
        <img src={groupDetail.image ?? COURSE_HTML_CSS} loading="lazy" alt="" />
      </AspectRatio>
      <CardContent className={styles.groupContent}>
        <div>
          <Typography className={styles.groupTitle} level="title-lg">
            {groupDetail.name}
          </Typography>
          <Typography className={styles.groupDescription} level="body-sm">
            {groupDetail.description ??
              'Learn to create and style your very own websites using HTML and CSS in this fun, hands-on course'}
          </Typography>
        </div>
        <div className={styles.groupLessonProgressContainer}>
          <LinearProgress variant="determinate" value={percentageCompleted} className={styles.groupLessonProgress} />
          <Typography level="body-sm" className={styles.groupLessonPercentage}>
            {percentageCompleted}%
          </Typography>
        </div>
        <Button
          variant="solid"
          size="md"
          color="success"
          aria-label="Enter course"
          sx={{ alignSelf: 'center', fontWeight: 600 }}
          className={styles.groupButton}
          onClick={() => history.push('/groups/' + groupDetail._id + '/' + lesson)}
        >
          Continue
        </Button>
      </CardContent>
    </Card>
  );
};

export default Group;
