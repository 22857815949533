import React, { useState } from 'react';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  Card, 
  CardContent, 
  Typography, 
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';
import styles from '../styles/NotificationPortal.module.css';

const FeedbackCard = ({ feedback, filterType }) => {
  const { jwt } = useApplicationContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [resolution, setResolution] = useState('');

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
    if (interval > 1) return Math.floor(interval) + ' years ago';
    
    interval = seconds / 2592000;
    if (interval > 1) return Math.floor(interval) + ' months ago';
    
    interval = seconds / 86400;
    if (interval > 1) return Math.floor(interval) + ' days ago';
    
    interval = seconds / 3600;
    if (interval > 1) return Math.floor(interval) + ' hours ago';
    
    interval = seconds / 60;
    if (interval > 1) return Math.floor(interval) + ' minutes ago';
    
    return Math.floor(seconds) + ' seconds ago';
  };

  const handleResolve = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SWC_API_URL}/feedback/resolve`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': jwt
        },
        body: JSON.stringify({
          feedbackArr: [{
            ...feedback,
            resolutionNote: resolution
          }]
        })
      });

      if (response.ok) {
        setOpenDialog(false);
        // Trigger a refresh of the feedback list
        window.location.reload();
      }
    } catch (error) {
      console.error('Error resolving feedback:', error);
    }
  };

  return (
    <>
      <Card className={styles.feedbackListItem} sx={{ marginBottom: 2, borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          <div className={styles.listItemHeader}>
            <div className={styles.listItemName}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: '1rem', color: '#005fcc' }}
              >
                {feedback.userId.fname + ' ' + feedback.userId.lname}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1rem', whiteSpace: 'pre-wrap' }}>
                {' provided feedback'}
              </Typography>
            </div>
            <Typography variant="body2" color="textSecondary">
              {timeSince(new Date(feedback.createdAt))}
            </Typography>
          </div>
          <Typography color="textSecondary" sx={{ fontWeight: 400, fontSize: '1rem', marginBottom: feedback.resolutionNote ? 2 : 0 }}>
            {'"' + feedback.content + '"'}
          </Typography>
          {feedback.resolutionNote && (
            <Box sx={{ 
              backgroundColor: '#f5f5f5', 
              padding: 2, 
              borderRadius: 1,
              marginBottom: 2
            }}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 500, color: '#2e7d32', marginBottom: 0.5 }}
              >
                Resolution Note:
              </Typography>
              <Typography color="textSecondary" sx={{ fontWeight: 400, fontSize: '0.875rem' }}>
                {feedback.resolutionNote}
              </Typography>
            </Box>
          )}
          <div className={styles.listItemFooter}>
            <Typography variant="body2" color="textSecondary">
              {`${feedback.groupId.name || ''} | ${feedback.lessonId.name}`}
            </Typography>
            {filterType === 'unresolved' && (
              <Button 
                variant="contained" 
                color="primary" 
                size="small"
                onClick={() => setOpenDialog(true)}
                sx={{ marginLeft: 2 }}
              >
                Resolve
              </Button>
            )}
          </div>
        </CardContent>
      </Card>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Resolve Feedback</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Resolution Explanation"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={resolution}
            onChange={(e) => setResolution(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleResolve} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FeedbackList = ({ feedbacks, filterType }) => (
  <Box>
    <List className={styles.list}>
      {feedbacks.length > 0 ? (
        feedbacks.map((feedback) => (
          <FeedbackCard 
            key={feedback.id} 
            feedback={feedback} 
            filterType={filterType}
          />
        ))
      ) : (
        <ListItem>
          <ListItemText primary={`No ${filterType} feedbacks available.`} />
        </ListItem>
      )}
    </List>
  </Box>
);

export default FeedbackList;