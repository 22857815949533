import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faRotateRight, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/joy';

import AddCommentForm from './AddCommentForm';
import CommentList from './CommentList';
import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';

import styles from '../styles/Comments.module.css';

function CommentSection() {
  const [topLevelComments, setTopLevelComments] = useState();
  const [sorted, setSorted] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [order, setOrder] = useState('newToOld');

  const { jwt } = useApplicationContext();

  let { groupId, lessonId } = useParams();

  const updateComment = (type, comment, parentCommentId) => {
    if (type === 'addTopLevelComment') {
      setTopLevelComments([comment, ...topLevelComments]);
    }
    if (type === 'addReply') {
      setTopLevelComments(
        topLevelComments.map((c) => {
          if (c._id !== parentCommentId) return c;
          else {
            return {
              ...c,
              replies: [...c.replies, comment],
            };
          }
        })
      );
    }
    if (type === 'deleteTopLevelComment') {
      setTopLevelComments(topLevelComments.filter((c) => c._id !== comment));
    }
    if (type === 'deleteReply') {
      setTopLevelComments(
        topLevelComments.map((c) => {
          if (c._id !== parentCommentId) return c;
          else {
            return {
              ...c,
              replies: c.replies.filter((r) => r._id !== comment),
            };
          }
        })
      );
    }
    if (type === 'addUpvote') {
      setTopLevelComments(
        topLevelComments.map((c) => {
          if (c._id !== parentCommentId) return c;
          else {
            return {
              ...c,
              upVotes: [...c.upVotes, 'currentUser'],
            };
          }
        })
      );
    }
    if (type === 'removeUpvote') {
      setTopLevelComments(
        topLevelComments.map((c) => {
          if (c._id !== parentCommentId) return c;
          else {
            return {
              ...c,
              upVotes: c.upVotes.slice(0, c.upVotes.length - 1),
            };
          }
        })
      );
    }
  };

  function newToOld(a, b) {
    if (a.createdAt < b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    return 0;
  }

  function oldToNew(a, b) {
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  function mostUpvoted(a, b) {
    if (a.upVotes.length < b.upVotes.length) return 1;
    if (a.upVotes.length > b.upVotes.length) return -1;
    return 0;
  }

  const sortComments = (order) => {
    if (order === 'newToOld') {
      setOrder('newToOld');
      setRefetch((current) => !current);
      setSorted(true);
    }
    if (order === 'oldToNew') {
      setOrder('oldToNew');
      setRefetch((current) => !current);
      setSorted(true);
    }
    if (order === 'mostUpvoted') {
      setOrder('mostUpvoted');
      setRefetch((current) => !current);
      setSorted(true);
    }
  };

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwt,
      },
    };
    const fetchComments = async () => {
      fetch(`${process.env.REACT_APP_SWC_API_URL}/comment?groupId=${groupId}&lessonId=${lessonId}`, requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (order === 'newToOld') setTopLevelComments(data.filter((c) => c.isReply === false).sort(newToOld));
          if (order === 'oldToNew') setTopLevelComments(data.filter((c) => c.isReply === false).sort(oldToNew));
          if (order === 'mostUpvoted') setTopLevelComments(data.filter((c) => c.isReply === false).sort(mostUpvoted));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchComments();
  }, [lessonId, refetch]);

  return (
    <div className={styles['comment--section']}>
      <div className={styles['comment--section--header']}>
        <div className={styles['comments--count']}>{topLevelComments ? topLevelComments.length : ''} Comments</div>
        <FontAwesomeIcon
          className={styles['faSort']}
          icon={!sorted ? faSort : order === 'newToOld' ? faSortUp : faSortDown}
        />
        <select onChange={(e) => sortComments(e.target.value)}>
          <option value="newToOld">Newest first</option>
          <option value="oldToNew">Oldest first</option>
          <option value="mostUpvoted">Most upvoted</option>
        </select>

        <Tooltip
          arrow
          placement="top"
          title={
            <React.Fragment>
              <Typography color="inherit" fontWeight="bold" fontSize="16px" sx={{ marginBottom: '8px' }}>
                Commenting Tips:
              </Typography>
              <ul style={{ paddingLeft: '20px', margin: 0, fontSize: '12px', lineHeight: '1.8' }}>
                <li style={{ marginBottom: '8px', listStyleType: 'dash' }}>
                  Add a comment to a video by typing in the comment box below
                </li>
                <li style={{ marginBottom: '8px', listStyleType: 'dash' }}>
                  Reply to a specific comment by clicking <em>Reply</em>
                </li>
                <li style={{ marginBottom: '8px', listStyleType: 'dash' }}>
                  Add code snippets to your comment by clicking <strong>"Add code snippet"</strong>
                </li>
                <li style={{ marginBottom: '8px', listStyleType: 'dash' }}>
                  Tag other users by typing <code>@</code> followed by their username
                </li>
                <li style={{ marginBottom: '8px', listStyleType: 'dash' }}>
                  Use <code>@SWC</code> to tag the course creators directly
                </li>
              </ul>
            </React.Fragment>
          }
        >
          <IconButton>
            <InfoIcon style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>

        <FontAwesomeIcon
          onClick={() => setRefetch((current) => !current)}
          className={styles['faRotateRight']}
          icon={faRotateRight}
        />
      </div>
      <AddCommentForm updateComment={updateComment}></AddCommentForm>
      {topLevelComments ? (
        <CommentList topLevelComments={topLevelComments} updateComment={updateComment}></CommentList>
      ) : (
        ''
      )}
    </div>
  );
}

export default CommentSection;
