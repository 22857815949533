import React from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import navMapper from '../../../config/navMapper';
import { useJoinSiteModal } from '../providers/JoinSiteModalProvider';

import { LOGO_TRANSPARENT } from '../../../constants/images';

import styles from '../styles/Header.module.css';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';

function Header() {
  const history = useHistory();
  const { openModal } = useJoinSiteModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { jwt } = useApplicationContext();

  const menuConfig = jwt ? navMapper.protected : navMapper.unprotected;
  const menuItems = isMobile ? menuConfig.xs : menuConfig.nonXs;

  const Notification = () => (menuItems.notification ? <></> : <></>);

  const performAction = (route, action) => {
    if (route) {
      history.push(route);
    } else {
      if (action === 'LearnMoreModal') {
        openModal();
      }
    }
  };

  const Buttons = () => (
    <div className={styles['header-buttons']} maxWidth={false} disableGutters>
      {menuItems.buttons.length ? menuItems.buttons.map((button) => renderButton(button)) : <></>}
    </div>
  );

  const renderButton = (button) => (
    <Button
      key={button.id}
      onClick={() => performAction(button.route, button.action)}
      color={button.color}
      variant={button.variant}
      endIcon={button.icon ? <button.icon /> : <></>}
      sx={{ fontWeight: 800, borderRadius: '15px' }}
    >
      {button.text}
    </Button>
  );

  const Hamburger = () =>
    menuItems.hamburger.length ? (
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ ml: 1 }}>
        <MenuIcon />
      </IconButton>
    ) : (
      <></>
    );

  return (
    <AppBar className={styles['header']} position="sticky" color="transparent">
      <Toolbar>
        <div className={styles['logo-container']}>
          <img onClick={() => performAction('/')} className={styles['logo']} src={LOGO_TRANSPARENT} alt='logo' />
        </div>
        <Notification />
        <Buttons />
        <Hamburger />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
