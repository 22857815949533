import React from 'react';

import styles from '../styles/Lesson.module.css';

const Tabs = ({ currentTab, setTabToNotes, setTabtoVideo }) => (
  <div className={styles['tabs']}>
    <button
      className={currentTab === 'video' ? styles['tab--btn'] + ' ' + styles['active'] : styles['tab--btn']}
      onClick={setTabtoVideo}
    >
      Comments
    </button>
    <button
      className={currentTab === 'notes' ? styles['tab--btn'] + ' ' + styles['active'] : styles['tab--btn']}
      onClick={setTabToNotes}
    >
      Notes
    </button>
  </div>
);

export default Tabs;
