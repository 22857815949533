import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { usePrevNextButtons } from '../hooks/usePrevNextButtons';
import { useDotButton } from '../hooks/useDotButton';
import { DesktopSlides, MobileSlides } from '../configs/platformSlidesMapper';

import styles from '../styles/Platform.module.css';

const PrevButton = (props) => (
  <IconButton className={styles['embla__button embla__button--prev']} type="button" {...props}>
    <NavigateBeforeIcon />
  </IconButton>
);

const NextButton = (props) => (
  <IconButton className={styles['embla__button embla__button--next']} type="button" {...props}>
    <NavigateNextIcon />
  </IconButton>
);

const DotButton = (props) => {
  return (
    <IconButton type="button" {...props}>
      {props.selected ? <RadioButtonCheckedIcon fontSize="small" /> : <RadioButtonUncheckedIcon fontSize="small" />}
    </IconButton>
  );
};

const Platform = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const mapper = isMobile ? MobileSlides : DesktopSlides;

  const slides = Array.from(Array(5).keys());

  return (
    <section className={styles['embla']}>
      <Typography className={styles.platformTitle} variant="h4" sx={{ fontWeight: 600, padding: '1rem' }}>
        Our Platform
      </Typography>
      <div className={styles['embla__viewport']} ref={emblaRef}>
        <div className={styles['embla__container']}>
          {slides.map((index) => (
            <div className={styles['embla__slide']} key={index}>
              <div className={styles['embla__slide__number']}>{index + 1}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles['embla__controls']}>
        <div className={styles['embla__buttons']}>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <div className={styles['embla__dots']}>
            {scrollSnaps.map((_, index) => (
              <DotButton key={index} onClick={() => onDotButtonClick(index)} selected={index === selectedIndex} />
            ))}
          </div>
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  );
};

export default Platform;
