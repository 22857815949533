import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { useJoinSiteModal } from '../../home/providers/JoinSiteModalProvider';

import styles from './styles/Footer.module.css';

const Footer = () => {
  const { openModal } = useJoinSiteModal();

  return (
    <div className={styles['footer']}>
      <div className={styles['call-to-action']}>
        <h5>Students Who Code</h5>
        <div className={styles['cta-buttons']}>
          <button onClick={openModal} className={styles['join-button']}>Learn More</button>
        </div>
      </div>
      <div className={styles['contact-us']}>
        <h5>Contact us</h5>
        <div className={styles['contact-icons']}>
          <a href= "mailto: team@studentswhocode.com">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>
          <a href="https://www.linkedin.com/company/studentswhocode" target="_blank">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <a href="https://www.instagram.com/studentswhocode" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
