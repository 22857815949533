import LoginIcon from '@mui/icons-material/Login';

const LearnMore = {
    id: 'learnMoreNav',
    variant: 'text',
    text: 'Learn More',
    action: 'LearnMoreModal',
    color: 'success',
};

const Login = {
    id: 'LoginNav',
    variant: 'contained',
    text: 'Login',
    route: '/login',
    color: 'success',
    icon: LoginIcon,
};

const AdminPortal = {
    id: 'AdminPortalNav',
    text: 'Admin Portal',
    route: '/adminportal',
    authLevel: ['teacher', 'orgadmin', 'swcadmin']
};

const NotificationPortal = {
    id: 'NotificationPortalNav',
    text: "Notification Portal",
    route: '/notificationportal',
    authLevel: ['swcadmin']
}

const ResetPassword = {
    id: 'ResetPasswordNav',
    text: 'Reset Password',
    route: '/reset'
};

const SignOut = {
    id: 'SignOutNav',
    text: 'Sign out',
    action: 'signout'
};

const Feedback = {
    id: 'FeedbackNav',
    text: 'Feedback',
    action: 'feedback',
    data: 'hasFeedback',
};

const navMapper = {
    unprotected: {
        xs: {
            hamburger: [],
            hasNotification: false,
            buttons: [LearnMore, Login]
        },
        nonXs: {
            hamburger: [],
            hasNotification: false,
            buttons: [LearnMore, Login]
        }
    },
    protected: {
        xs: {
            hamburger: [AdminPortal, NotificationPortal, ResetPassword, SignOut],
            hasNotification: true,
            buttons: [Feedback]
        },
        nonXs: {
            hamburger: [ResetPassword, SignOut],
            hasNotification: true,
            buttons: [NotificationPortal, Feedback, AdminPortal]
        }
    }
};

export default navMapper;