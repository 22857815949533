import React, { createContext, useState, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Modal, Box, Typography, TextField, Button, TextareaAutosize, Alert } from '@mui/material';
import styles from '../styles/JoinSiteModal.module.css';

// Create a context
const JoinSiteModalContext = createContext();

// Create a provider component
export const JoinSiteModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  function closeSnackBar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  }

  async function submitToMailingList() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fullName, message, email }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/external/mailingList`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          setSnackMessage('Thank you for subscribing!');
          setSnackOpen(true);
          closeModal();
        } else {
          setSnackMessage("You're already part of our mailing list");
          setSnackOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToMailingList();
    closeModal();
  };

  const value = {
    isOpen,
    openModal,
    closeModal,
    fullName,
    setFullName,
    email,
    setEmail,
    message,
    setMessage,
    handleSubmit,
  };

  return (
    <JoinSiteModalContext.Provider value={value}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={closeSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackMessage}
      >
        <Alert onClose={closeSnackBar} severity="success" variant="filled" sx={{ width: '100%' }}>{snackMessage}</Alert>
      </Snackbar>
      {children}
      <Modal open={isOpen} onClose={closeModal} className={styles['modal']}>
        <Box className={styles['modal-content']}>
          <Typography variant="h4" component="h2" gutterBottom>
            Let's Get Started
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Fill in the fields below, and let's bring the future of education to your doorstep</b>
          </Typography>
          <form onSubmit={handleSubmit} className={styles['form']}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              required
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextareaAutosize
              aria-label="Message"
              minRows={4}
              placeholder="Your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={styles['textarea']}
            />
            <Button type="submit" variant="contained" color="success" size="large">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </JoinSiteModalContext.Provider>
  );
};

// Custom hook to use the modal context
export const useJoinSiteModal = () => {
  const context = useContext(JoinSiteModalContext);
  if (!context) {
    throw new Error('useJoinSiteModal must be used within a JoinSiteModalProvider');
  }
  return context;
};
