import React, { useEffect, useState, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Drawer,
  LinearProgress,
  setRef,
} from '@mui/material';
import Button from '@mui/joy/Button';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';

import navMapper from '../../../config/navMapper';
import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';
import NotificationCenter from './components/NotificationCenter';
import FeedbackForm from '../../lesson/components/FeedbackForm';
import { LOGO_TRANSPARENT } from '../../../constants/images';
import styles from './styles/Header.module.css';

const Header = ({ disableGutter, lessons, hasFeedback }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [forceUpdate, SetForceUpdate] = useState(false);

  let { groupId, lessonId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { jwt, setJwt, authLevel } = useApplicationContext();

  const mapper = jwt ? navMapper.protected : navMapper.unprotected;
  const { hamburger, buttons, hasNotification } = isMobile ? mapper.xs : mapper.nonXs;

  const ref = useRef();

  useEffect(() => {
    if (ref && ref.current)
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }, [forceUpdate]);

  const retake = (lessonId) => {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwt },
      body: JSON.stringify({ lessonId: lessonId }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/user/retakequiz`, requestOptions)
      .then((res) => {
        if (res.status === 200) changeLesson(lessonId);
        else console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const toggleDrawer = (state) => () => {
    setDrawerOpen(state);
    SetForceUpdate(false);
  };

  const feedback = () => {
    setDrawerOpen(false);
    setShowFeedbackForm(true);
  };

  const signout = () => {
    localStorage.clear();
    setJwt('');
    history.push('/');
    window.location.reload();
  };

  const changeLesson = (lessonId) => {
    setDrawerOpen(false);
    history.push('/groups/' + groupId + '/' + lessonId);
  };

  const performAction = (action, data) => {
    if (action === 'signout') {
      signout();
    }
    if (action === 'changeLesson') {
      changeLesson(data);
    }
    if (action === 'feedback') {
      feedback();
    }
    if (action === 'retake') {
      retake(data);
    }
  };

  const checkData = (item) => {
    if (!item.data) {
      return true;
    }

    if (item.data === 'hasFeedback' && hasFeedback) {
      return true;
    }

    return false;
  };

  const checkAuthLevel = (item) => {
    if (!item.authLevel) {
      return true;
    }

    return item.authLevel.includes(authLevel);
  };

  const determineEligibility = (item) => {
    const hasAuthLevel = checkAuthLevel(item);
    const hasData = checkData(item);
    return hasAuthLevel && hasData;
  };

  const determineActiveState = (item) => {
    if (!item.route) {
      return false;
    }
    const route = item?.route ?? '';
    return location.pathname.indexOf(route) !== -1;
  };

  const MenuItems = () =>
    buttons && (
      <div style={{ display: 'flex' }}>
        {buttons.map((item, index) => {
          const hasAction = item?.action;
          const hasPrivilege = determineEligibility(item);
          const isActive = determineActiveState(item);

          return hasPrivilege ? (
            hasAction ? (
              <Typography
                key={item.id}
                variant="h6"
                onClick={() => performAction(item.action)}
                style={{ textDecoration: 'none', marginLeft: '20px', fontWeight: 600 }}
                className={isActive ? styles.activeMenuItem : styles.menuItem}
              >
                {item.text}
              </Typography>
            ) : (
              <Typography
                key={index}
                variant="h6"
                component={Link}
                to={item.route}
                style={{ textDecoration: 'none', marginLeft: '20px', fontWeight: 600 }}
                className={isActive ? styles.activeMenuItem : styles.menuItem}
              >
                {item.text}
              </Typography>
            )
          ) : (
            <></>
          );
        })}
      </div>
    );

  const HamburgerMenuItems = () =>
    hamburger.map((item, index) => {
      const hasAction = item?.action;
      const hasPrivilege = determineEligibility(item);
      const isActive = determineActiveState(item);

      return hasPrivilege ? (
        hasAction ? (
          <ListItem
            button
            key={index}
            onClick={() => {
              performAction(item.action);
              toggleDrawer(false);
            }}
            className={isActive ? styles.activeHamburgerMenuItems : styles.hamburgerMenuItems}
          >
            <ListItemText
              primary={item.text}
              className={styles.hamburgerMenuItemText}
              primaryTypographyProps={{
                variant: 'h6',
              }}
            />
          </ListItem>
        ) : (
          <ListItem
            button
            key={index}
            component={Link}
            to={item.route}
            onClick={toggleDrawer(false)}
            className={isActive ? styles.activeHamburgerMenuItems : styles.hamburgerMenuItems}
          >
            <ListItemText
              primary={item.text}
              className={styles.hamburgerMenuItemText}
              primaryTypographyProps={{
                variant: 'h6',
              }}
            />
          </ListItem>
        )
      ) : (
        <></>
      );
    });

  const HamburgerMenu = () => (
    <IconButton color="inherit" onClick={toggleDrawer(true)} style={{ marginLeft: '20px' }}>
      <MenuIcon fontSize="large" sx={{ color: '#0a6d0e' }} />
    </IconButton>
  );

  const ProgressBar = ({ lesson }) => {
    if (lesson.percWatched === 0)
      return <LinearProgress variant="determinate" value={lesson.percWatched} className={styles.lessonProgress} />;
    return (
      <div className={styles.lessonProgressContainer}>
        <LinearProgress variant="determinate" value={lesson.percWatched} className={styles.lessonProgress} />
        <Typography variant="body2" className={styles.lessonPercentage}>
          {lesson.percWatched}%
        </Typography>
      </div>
    );
  };

  const VideoLesson = ({ lesson, index }) => (
    <ListItem
      key={index}
      className={`${styles.lessonItem} ${lesson._id === lessonId ? styles.activeLessonItem : ''}`}
      onClick={() => performAction('changeLesson', lesson._id)}
      ref={lesson._id === lessonId ? ref : null}
    >
      <div className={styles.lessonContent}>
        <Typography variant="h6" className={styles.lessonTitle}>
          {lesson.name}
        </Typography>
        <Typography variant="body2" className={styles.lessonDescription}>
          {lesson.description}
        </Typography>
        <ProgressBar lesson={lesson} />
      </div>
    </ListItem>
  );

  const QuizLesson = ({ lesson, index }) => {
    const isComplete = lesson.quizComplete;
    const answers = lesson.quizAnswers;
    const score = lesson.quizScore;

    const notStarted = !isComplete && answers.length === 0;
    const inProgress = !isComplete && answers.length !== 0;

    return (
      <ListItem
        key={index}
        className={`${styles.lessonItem} ${lesson._id === lessonId ? styles.activeLessonItem : ''}`}
        onClick={() => performAction('changeLesson', lesson._id)}
        ref={lesson._id === lessonId ? ref : null}
      >
        <div className={styles.lessonContent}>
          <Typography variant="h6" className={styles.lessonTitle}>
            {lesson.name}
          </Typography>
          <Typography variant="body2" className={styles.lessonDescription}>
            {lesson.description}
          </Typography>
          {notStarted && (
            <Typography variant="h5" sx={{ fontSize: '1rem' }} className={styles.quizStatus}>
              Not Started Yet
            </Typography>
          )}
          {inProgress && (
            <Typography variant="h5" sx={{ fontSize: '1rem' }} className={styles.quizStatus}>
              Continue
            </Typography>
          )}
          {isComplete && (
            <div className={styles.quizItem}>
              <Button
                variant="solid"
                size="md"
                color="success"
                aria-label="Retake"
                sx={{ alignSelf: 'center', fontWeight: 600 }}
                className={styles.groupButton}
                onClick={() => performAction('retake', lesson._id)}
              >
                Retake
              </Button>
              <Typography variant="body2" className={styles.quizScore}>
                {score}%
              </Typography>
            </div>
          )}
        </div>
      </ListItem>
    );
  };

  const LessonDrawerContent = () => (
    <div className={styles.lessonContainer}>
      {lessons && (
        <List className={styles.lessonDrawer}>
          <Typography className={styles.lessonHeader} variant="h4" sx={{ fontWeight: 600, padding: '1rem' }}>
            Lessons
          </Typography>
          {lessons.map((lesson, index) => {
            const isVideoLesson = Object.hasOwn(lesson, 'percWatched');
            const isQuiz = Object.hasOwn(lesson, 'quizComplete');

            if (isVideoLesson) {
              return <VideoLesson lesson={lesson} index={index} />;
            } else if (isQuiz) {
              return <QuizLesson lesson={lesson} index={index} />;
            } else {
              return <></>;
            }
          })}
        </List>
      )}
      <div className={styles.hamburgerContainer}>
        <HamburgerMenuItems />
      </div>
      {SetForceUpdate(true)}
    </div>
  );

  return (
    <AppBar
      className={styles.header}
      sx={{ backgroundColor: 'rgb(145 255 145 / 100%)', marginBottom: disableGutter ? '0rem' : '5rem' }}
      position="sticky"
    >
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Link style={{ display: 'flex' }} to="/">
          <img className={styles['logo']} src={LOGO_TRANSPARENT} alt="logo" />
        </Link>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {hasNotification && <NotificationCenter />}
          <MenuItems />
          {hamburger.length !== 0 && <HamburgerMenu />}
        </div>

        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: '#ffffff',
              width: '300px',
            },
          }}
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          <LessonDrawerContent />
        </Drawer>
        {showFeedbackForm && <FeedbackForm setShowFeedbackForm={setShowFeedbackForm}></FeedbackForm>}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
