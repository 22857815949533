import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReactPlayer from 'react-player';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';
import CommentSection from './CommentSection';

import styles from '../styles/Video.module.css';

const Video = ({ videoUrl, currentLesson, listOfLessons, changeLesson, setVideoUpdatePromise }) => {
  let { groupId, lessonId } = useParams();
  const vidStatsRef = useRef({ percentageWatched: 0, secondsWatched: 0 });
  const [fullscreenMode, setFullscreenMode] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { jwt, updateLessonProgress, authLevel } = useApplicationContext();

  let previousLesson = undefined;
  let nextLesson = undefined;
  listOfLessons.forEach((lesson, index) => {
    if (lesson._id === currentLesson) {
      previousLesson = listOfLessons[index - 1];
      nextLesson = listOfLessons[index + 1];
    }
  });

  useEffect(() => {
    return () => {
      const currentProgress = Math.round(vidStatsRef.current.percentageWatched * 100);
      const progressData = {
        percWatched: currentProgress,
        secondsWatched: vidStatsRef.current.secondsWatched,
      };

      if (currentProgress > 0) {
        updateLessonProgress(groupId, lessonId, progressData);
        if (authLevel !== 'swcadmin') {
          const requestOptions = {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': jwt,
            },
            body: JSON.stringify({
              percWatched: currentProgress,
              currPercWatched: currentProgress,
              secondsWatched: vidStatsRef.current.secondsWatched,
              groupId: groupId,
              lessonId: lessonId,
              lessonType: 'lesson',
            }),
          };
          const updatePromise = fetch(`${process.env.REACT_APP_SWC_API_URL}/user/updatestats`, requestOptions);
          setVideoUpdatePromise(updatePromise);
        }
      }
    };
  }, [lessonId, groupId, updateLessonProgress, authLevel, jwt, setVideoUpdatePromise]);

  const handleProgress = useCallback((state) => {
    vidStatsRef.current = {
      percentageWatched: state.played,
      secondsWatched: state.playedSeconds,
    };
  }, []);

  const handleLessonChange = useCallback(
    (newLessonId) => {
      const currentProgress = Math.round(vidStatsRef.current.percentageWatched * 100);
      const progressData = {
        percWatched: currentProgress,
        secondsWatched: vidStatsRef.current.secondsWatched,
      };

      if (currentProgress > 0) {
        updateLessonProgress(groupId, lessonId, progressData);
        if (authLevel !== 'swcadmin') {
          const requestOptions = {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': jwt,
            },
            body: JSON.stringify({
              percWatched: currentProgress,
              currPercWatched: currentProgress,
              secondsWatched: vidStatsRef.current.secondsWatched,
              groupId: groupId,
              lessonId: lessonId,
              lessonType: 'lesson',
            }),
          };
          const updatePromise = fetch(`${process.env.REACT_APP_SWC_API_URL}/user/updatestats`, requestOptions);
          setVideoUpdatePromise(updatePromise);
        }
      }

      changeLesson(newLessonId);
    },
    [groupId, updateLessonProgress, authLevel, jwt, setVideoUpdatePromise, changeLesson]
  );

  function handlePause() {}
  function handleEnded() {}
  function handleDuration(duration) {}

  const VideoPlayer = (
    <div className={styles['video__container']}>
      <ReactPlayer
        className={styles['video__player']}
        url={videoUrl}
        width="100%"
        height="fit-content"
        controls={true}
        pip={true}
        onProgress={handleProgress}
        onPause={handlePause}
        onEnded={handleEnded}
        onDuration={handleDuration}
        playsinline
      />
      <button
        className={`${styles['video__nav-button']} ${styles['video__nav-button--prev']}`}
        onClick={() => handleLessonChange(previousLesson?._id)}
        disabled={!previousLesson}
      >
        <ChevronLeftIcon />
      </button>
      <button
        className={`${styles['video__nav-button']} ${styles['video__nav-button--next']}`}
        onClick={() => handleLessonChange(nextLesson?._id)}
        disabled={!nextLesson}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );

  return (
    <div className={styles['video']}>
      {VideoPlayer}
      {!isMobile && <CommentSection />}
    </div>
  )};

export default Video;
