import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../shared/header/Header';
import BackgroundDoodle from '../shared/BackgroundDoodle';
import { useApplicationContext } from '../../contexts/ApplicationContextProvider';
import { resetPasswordDoodle } from '../../config/doodleMapper';

import styles from './styles/ResetPassword.module.css';
import { backdropClasses } from '@mui/material';

const ResetPassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showOldPw, setShowOldPw] = useState(false);
  const [showNewPw, setShowNewPw] = useState(false);
  const [showIncorrectOldPw, setShowIncorrectOldPw] = useState(false);
  const [minChar, setMinChar] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [num, setNum] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [notSame, setNotSame] = useState(false);
  const [strength, setStrength] = useState(0);
  const [strengthType, setStrengthType] = useState('Very Weak');
  const [strengthStyle, setStrengthStyle] = useState({
    color: 'red',
  });
  const [indicatorBarStyles, setIndicatorBarStyles] = useState({
    width: '0%',
    backgroundColor: 'transparent',
  });
  const [oneChar, setOneChar] = useState(false);
  const [isMaxStrength, setIsMaxStrength] = useState(false);

  const { jwt, setToast } = useApplicationContext();

  const currentPwRef = useRef(null);
  const newPwRef = useRef(null);

  const history = useHistory();

  const toggleShowPw = (pwType) => {
    if (pwType === 'old') setShowOldPw(!showOldPw);
    if (pwType === 'new') setShowNewPw(!showNewPw);
  };

  function resetPasswordCall() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwt },
      body: JSON.stringify({ oldPassword: oldPassword, newPassword: newPassword }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/auth/resetPassword`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          setToast({
            checkMark: true,
            message: 'Your password has been changed.',
            time: 3,
          });
          history.push('/');
        }
        if (response.status === 401) {
          setShowIncorrectOldPw(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    resetPasswordCall();
  }

  const validate = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length >= 8) setMinChar(true);
    else setMinChar(false);
    if (/[a-z]/.test(e.target.value)) setLowerCase(true);
    else setLowerCase(false);
    if (/[A-Z]/.test(e.target.value)) setUpperCase(true);
    else setUpperCase(false);
    if (/\d/.test(e.target.value)) setNum(true);
    else setNum(false);
    if (/[@$!%*?&]/.test(e.target.value)) setSpecialChar(true);
    else setSpecialChar(false);
    if (oldPassword !== e.target.value) setNotSame(true);
    else setNotSame(false);
  };

  const checkNotSame = (e) => {
    setOldPassword(e.target.value);
    if (e.target.value !== newPassword) setNotSame(true);
    else setNotSame(false);
  };

  const handleFocus = (e) => {
    if (e.target.id === 'current') {
      currentPwRef.current.classList.add(styles['pw-holder-focus']);
    }
    if (e.target.id === 'new') {
      newPwRef.current.classList.add(styles['pw-holder-focus']);
    }
  };

  const handleBlur = (e) => {
    if (e.target.id === 'current') {
      currentPwRef.current.classList.remove(styles['pw-holder-focus']);
    }
    if (e.target.id === 'new') {
      newPwRef.current.classList.remove(styles['pw-holder-focus']);
    }
  };

  // Password Strength Indicator bar that re-renders upon ever change in the new password value //

  useEffect(() => {
    const updatedIndicatedBarStyles = {
      width: 0.01,
    };

    const updatedStrengthStyle = {
      color: 'red',
    };

    setOneChar(false);

    let totalStrength = 0;

    if (newPassword.length >= 8) {
      let strengthByLength = 2;
      let strengthByCharacterType = 0;
      setOneChar(true);

      if (/[a-z]/.test(newPassword)) {
        strengthByCharacterType += 1;
      }
      if (/[A-Z]/.test(newPassword)) {
        strengthByCharacterType += 1;
      }
      if (/\d/.test(newPassword)) {
        strengthByCharacterType += 1;
      }
      if (/[@$!%*?&]/.test(newPassword)) {
        strengthByCharacterType += 1;
      }

      totalStrength = strengthByCharacterType + strengthByLength;
    } else if (newPassword.length >= 1) {
      totalStrength = 1;
      setOneChar(true);
    } else {
      totalStrength = 0;
      setOneChar(false);
    }

    let currentStrengthType = 'Very Weak';

    if (totalStrength == 6) {
      // updatedIndicatedBarStyles.backgroundColor = 'green';
      updatedIndicatedBarStyles.width = '100%';
      updatedStrengthStyle.color = 'green';
      currentStrengthType = 'Excellent';
      setIsMaxStrength(true);
    } else if (totalStrength > 3) {
      // updatedIndicatedBarStyles.backgroundColor = 'yellow';
      updatedIndicatedBarStyles.width = '66.6%';
      updatedStrengthStyle.color = 'gold';
      currentStrengthType = 'Good';
    } else if (totalStrength > 0) {
      // updatedIndicatedBarStyles.backgroundColor = 'orange';
      updatedIndicatedBarStyles.width = '33.3%';
      updatedStrengthStyle.color = 'orange';
      currentStrengthType = 'Weak';
    } else {
      // updatedIndicatedBarStyles.backgroundColor = 'transparent';
      updatedIndicatedBarStyles.width = '0.01%';
      updatedStrengthStyle.color = 'grey';
      currentStrengthType = 'Empty';
    }

    setStrengthType(currentStrengthType);
    setStrength(totalStrength);
    setStrengthStyle(updatedStrengthStyle);
    setIndicatorBarStyles(updatedIndicatedBarStyles);
  }, [newPassword]);

  return (
    <div className={styles.resetPassword}>
      <BackgroundDoodle styles={styles.doodle} doodle={resetPasswordDoodle} />
      <div className={styles['header-container']}>
        <Header />
      </div>
      <div className={styles['resetPassword--content']}>
        <div className={styles['form-container']}>
          <div className={styles['form']}>
            <form>
              <p className={styles['form-title']}>Change Your Password</p>
              <div ref={currentPwRef} className={styles['pw-holder']}>
                <input
                  id="current"
                  type={showOldPw ? 'text' : 'password'}
                  value={oldPassword}
                  onChange={(e) => {
                    checkNotSame(e);
                  }}
                  placeholder="Current Password"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <span
                  onClick={() => {
                    toggleShowPw('old');
                  }}
                >
                  {showOldPw ? 'Hide' : 'Show'}
                </span>
              </div>
              {showIncorrectOldPw && <div className={styles['incorrent-pw']}>Incorrect Password</div>}
              <div ref={newPwRef} className={styles['pw-holder']}>
                <input
                  id="new"
                  type={showNewPw ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => {
                    validate(e);
                  }}
                  placeholder="New Password"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <span
                  onClick={() => {
                    toggleShowPw('new');
                  }}
                >
                  {showNewPw ? 'Hide' : 'Show'}
                </span>
              </div>

              <div>
                <div className={styles['password-indicator-container']}>
                  <div className={styles['password-indicator']} style={{ ...indicatorBarStyles }} />
                </div>
              </div>
              <p className={styles['strength']} style={{ ...strengthStyle }}>
                {strengthType}
              </p>

              {/* Added styling for new password validation layout */}
              <div
                className={styles['critera-container']}
                style={{
                  display: oneChar ? 'block' : 'none',
                }}
              >
                <div
                  style={{
                    display: notSame ? 'block' : 'none',
                  }}
                >
                  <p className={styles['normal-p']}>Password must be atleast:</p>
                  <p className={styles['normal-p']}></p>
                  <ul>
                    <li className={minChar ? styles.green : styles.red}>8 characters</li>
                    <li className={lowerCase ? styles.green : styles.red}> One lowercase letter </li>
                    <li className={upperCase ? styles.green : styles.red}> One uppercase letter</li>
                    <li className={num ? styles.green : styles.red}> One number</li>
                    <li className={specialChar ? styles.green : styles.red}>One special character: @$!%*?</li>
                  </ul>
                </div>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    display: oneChar ? 'block' : 'none',
                  }}
                >
                  <div
                    style={{
                      color: notSame ? 'green' : 'red',
                      display: notSame ? 'none' : 'block',
                    }}
                  >
                    Current password and new password must be different
                  </div>
                </div>
              </div>
              <button
                onClick={handleSubmit}
                className={
                  !minChar || !lowerCase || !upperCase || !num || !specialChar || !notSame || oldPassword === ''
                    ? styles.disabled
                    : styles.enabled
                }
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
