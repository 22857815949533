// BACKGROUNDS
export const BACKGROUND_GREEN_CODE = "https://swc-static-images.s3.amazonaws.com/background/background_green-code.png"

// COURSES
export const COURSE_HTML_CSS = "https://swc-static-images.s3.amazonaws.com/courses/course_html-css.png"
export const COURSE_JAVASCRIPT = ""

// HOME PAGE
export const HOMEPAGE_APP = "https://swc-static-images.s3.amazonaws.com/homepage/homepage_app.png"
export const HOMEPAGE_FUTURE = "https://swc-static-images.s3.amazonaws.com/homepage/homepage_future.png"
export const HOMEPAGE_GAME = "https://swc-static-images.s3.amazonaws.com/homepage/homepage_game.png"
export const HOMEPAGE_WEBSITE = "https://swc-static-images.s3.amazonaws.com/homepage/homepage_website.png"
export const HOMEPAGE_MISSION = "https://swc-static-images.s3.amazonaws.com/homepage/homepage_mission.png"
export const PARTNER_PENNYAPPEAL = "https://swc-static-images.s3.amazonaws.com/homepage/partner_pennyappeal.png"
export const PARTNER_PS43 = "https://swc-static-images.s3.amazonaws.com/homepage/partner_ps43.png"
export const STUDENTPROJECT_JEANELLE = "https://swc-static-images.s3.amazonaws.com/homepage/student-project_jeanelle.gif"
export const STUDENTPROJECT_JENUSHAN = "https://swc-static-images.s3.amazonaws.com/homepage/student-project_jenushan.gif"
export const STUDENTPROJECT_MARYAMA = "https://swc-static-images.s3.amazonaws.com/homepage/student-project_maryama.gif"

// MISCELLANEOUS
export const BUDDY1 = "https://swc-static-images.s3.amazonaws.com/miscellaneous/buddy.png"
export const BUDDY2 = "https://swc-static-images.s3.amazonaws.com/miscellaneous/buddy2.png"

// RESET PASSWORD
export const RESETPASSWORD_RAINBOW = "https://swc-static-images.s3.amazonaws.com/resetpassword/resetpassword_rainbow.png"
export const RESETPASSWORD_STRIPES = "https://swc-static-images.s3.amazonaws.com/resetpassword/resetpassword_stripes.png"

// SWC LOGO
export const LOGO_SOLID = "https://swc-static-images.s3.amazonaws.com/swc-logo/logo-solid.png"
export const LOGO_TRANSPARENT = "https://swc-static-images.s3.amazonaws.com/swc-logo/logo-transparent.png"