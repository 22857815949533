import React from 'react';

import { Typography } from '@mui/material';

import { HOMEPAGE_APP } from '../../../constants/images';
import { HOMEPAGE_WEBSITE } from '../../../constants/images';
import { HOMEPAGE_GAME } from '../../../constants/images';
import { HOMEPAGE_FUTURE } from '../../../constants/images';

import styles from '../styles/CreateSection.module.css';

const GridItem = ({ title, image, altText, text, gridStyle }) => {
  return (
    <div className={styles[gridStyle]}>
      <img src={image} alt={altText} className={styles.image} />
      <Typography variant="h6">{title}</Typography>
    </div>
  );
};

const CreateSection = () => (
  <div className={styles.createContainer}>
    <Typography className={styles.createTitle} variant="h4" sx={{ fontWeight: 600, padding: '1rem' }}>
      Write Code to Create
    </Typography>
    <div className={styles.gridContainer}>
      <GridItem
        title="Apps you want to use"
        image={HOMEPAGE_APP}
        altText="Image of a phone showing an app"
        gridStyle="grid1"
      />
      <GridItem
        title="Websites for the world to see"
        image={HOMEPAGE_WEBSITE}
        altText="Image of a phone showing an app"
        gridStyle="grid2"
      />
      <GridItem
        title="Games for everyone to play"
        image={HOMEPAGE_GAME}
        altText="Image of a phone showing an app"
        gridStyle="grid3"
      />
      <GridItem
        title="Make the impossible, possible"
        image={HOMEPAGE_FUTURE}
        altText="Image of a phone showing an app"
        gridStyle="grid4"
      />
    </div>
  </div>
);

export default CreateSection;
