import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

import styles from '../styles/NotificationPortal.module.css';

const SortFilter = ({ sortOrder, setSortOrder }) => {
  return (
    <div className={styles.toggleButtonGroupContainer}>
          <ToggleButtonGroup
            value={sortOrder}
            exclusive
            onChange={(e, newSortOrder) => setSortOrder(newSortOrder)}
            className={styles.toggleButtonGroup}
          >
            <ToggleButton className={styles.toggleButton} value="newest">Sort by Newest</ToggleButton>
            <ToggleButton className={styles.toggleButton} value="oldest">Sort by Oldest</ToggleButton>
          </ToggleButtonGroup>
        </div>
  );
};

export default SortFilter;
