import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Split from 'react-split';

import Header from '../shared/header/Header';
import Video from './components/Video';
import Note from './components/Note';
import Quiz from '../quiz/Quiz';
import CommentSection from './components/CommentSection';
import Buddy from './components/Buddy';
import Tabs from './components/Tabs';
import { useApplicationContext } from '../../contexts/ApplicationContextProvider';
import { useLesson } from '../../hooks/useLesson';

import styles from './styles/Lesson.module.css';
import './styles/Gutter.css';

const Lesson = () => {
  const [lessons, setLessons] = useState([]);
  const [video, setVideo] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [quizDetails, setQuizDetails] = useState({});
  const [lessonType, setLessonType] = useState('');
  const [currentTab, setCurrentTab] = useState('video');
  const [showFeedbackBtn, setShowFeedbackBtn] = useState(false);

  const videoUpdatePromiseRef = useRef(Promise.resolve());
  const isChangingLessonRef = useRef(false);

  const { groupId, lessonId } = useParams();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { jwt, setJwt, updateLastLessonViewed } = useApplicationContext();
  const { data, error, isLoading, refetch } = useLesson(jwt, setJwt, lessonId, groupId);
  let isDataReady = !isLoading && !error;

  useEffect(() => {
    refetch();
  }, [lessonId, refetch])

  useEffect(() => {
    let isMounted = true;

    const loadLessonData = async () => {
      if (isMounted && isDataReady) {
        if (isChangingLessonRef.current) {
          await videoUpdatePromiseRef.current;
          isChangingLessonRef.current = false;
          refetch()
        }

        updateLastLessonViewed(groupId, lessonId);

        if (data.lessonType === 'quiz') {
          setLessonType(data.lessonType);
          setLessons(data.lessons);
          setShowFeedbackBtn(!data.hasFeedback);
          setQuizDetails(data);
        } else {
          setLessonType(data.lessonType);
          setLessons(data.lessons);
          setShowFeedbackBtn(!data.hasFeedback);
          setVideo(data.videoUrl);
          setMarkdown(data.markdownText);
        }
      }
    };

    loadLessonData();

    return () => {
      isMounted = false;
    };
  }, [lessonId, isDataReady, data]);

  const setTabtoVideo = useCallback(() => setCurrentTab('video'), []);

  const setTabToNotes = useCallback(() => setCurrentTab('notes'), []);

  const changeLessonPage = useCallback((lesson) => {
    isChangingLessonRef.current = true;
    history.push('/groups/' + groupId + '/' + lesson);
  }, [history, lessonId]);

  const setVideoUpdatePromise = useCallback((promise) => {
    isChangingLessonRef.current = true;
    videoUpdatePromiseRef.current = promise;
  }, []);

  const CommentSectionContainer = () => (
    <div className={currentTab === 'video' ? styles['tab--content'] + ' ' + styles['active'] : styles['tab--content']}>
      <CommentSection />
    </div>
  )

  const NoteContainer = () => (
    <div className={currentTab === 'notes' ? styles['tab--content'] + ' ' + styles['active'] : styles['tab--content']}>
      <Note markdownText={markdown} />
    </div>
  );

  const MemoizedVideo = useMemo(() => (
    <Video
      videoUrl={video}
      currentLesson={lessonId}
      listOfLessons={lessons}
      changeLesson={changeLessonPage}
      setVideoUpdatePromise={setVideoUpdatePromise}
    />
  ), [video, lessonId, lessons, changeLessonPage, setVideoUpdatePromise]);

  const MobileLesson = () => (
    <div>
      <div className={styles['tabs--view']}>
        <div className={styles['tab--content--wrapper']}>
          <div className={styles.emptyContainer}></div>
          <Tabs currentTab={currentTab} setTabtoVideo={setTabtoVideo} setTabToNotes={setTabToNotes} />
          <CommentSectionContainer />
          <NoteContainer />
        </div>
      </div>
      <Buddy />
    </div>
  );

  const DesktopLesson = () => (
    <div>
      <Split
        sizes={[50, 50]}
        gutterSize={10}
        gutterAlign="center"
        direction="horizontal"
        className={styles['video__note']}
      >
        {MemoizedVideo}
        <Note markdownText={markdown} />
      </Split>
      <Buddy />
    </div>
  );

  const QuizLesson = useMemo(() => <Quiz quizDetails={quizDetails} />, [quizDetails]);

  const renderMobileLesson = () => {
    if (lessonType === 'quiz' && Object.keys(quizDetails).length) {
      return <>{QuizLesson}</>;
    } else if (lessonType === 'lesson') {
      return <MobileLesson />;
    } else {
      return <></>;
    }
  };

  const renderDesktopLesson = () => {
    if (lessonType === 'quiz' && Object.keys(quizDetails).length) {
      return <>{QuizLesson}</>;
    } else if (lessonType === 'lesson') {
      return <DesktopLesson />;
    } else {
      return <></>;
    }
  };

  return isDataReady ? (isMobile ? (
    <div className={styles['lesson__small']}>
      <Header disableGutter={true} lessons={lessons} hasFeedback={showFeedbackBtn} />
      {lessonType === 'lesson' && MemoizedVideo}
      <div className={styles.content}>{renderMobileLesson()}</div>
    </div>
  ) : (
    <div className={styles['lesson__large']}>
      <Header disableGutter={true} lessons={lessons} hasFeedback={showFeedbackBtn} />
      <div className={styles['lesson__main']}>
        <div className={styles['lesson__content']}>{renderDesktopLesson()}</div>
      </div>
    </div>
  )) : <></>
};

export default Lesson;
