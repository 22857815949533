import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { useApplicationContext } from '../../contexts/ApplicationContextProvider';
import Header from '../shared/header/Header';

import styles from './styles/AdminPortal.module.css';

function AdminGrps(props) {
  const [grps, setGrps] = useState();

  const { jwt, setJwt, authLevel, setAuthLevel} = useApplicationContext();

  let { orgId } = useParams();
  const history = useHistory();

  if (!props.location.state) {
    history.push({
      pathname: `/adminportal`,
      state: {
        orgId: orgId,
      },
    });
  }

  useEffect(() => {
    if (grps && grps.length === 1) {
      history.replace({
        pathname: `/adminportal/${orgId}/${grps[0]._id}`,
        state: { grpName: grps[0].name, orgName: props.location.state.orgName },
      });
    }
    if (props.location.state) {
      if (props.location.state.grpId && props.location.state.userId) {
        history.replace({
          pathname: `/adminportal/${orgId}/${props.location.state.grpId}`,
          state: {
            orgName: props.location.state.orgName,
            grpName: grps.find((grp) => grp._id === props.location.state.grpId).name,
            userId: props.location.state.userId,
          },
        });
      } else if (props.location.state.grpId) {
        history.replace({
          pathname: `/adminportal/${orgId}/${props.location.state.grpId}`,
          state: {
            orgName: props.location.state.orgName,
            grpName: grps.find((grp) => grp._id === props.location.state.grpId).name,
          },
        });
      }
    }
  }, [grps])

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwt },
    };
    if (authLevel === 'swcadmin') {
      fetch(`${process.env.REACT_APP_SWC_API_URL}/org?orgId=${orgId}`, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            localStorage.removeItem('swc-access-token');
            localStorage.removeItem('swc-user-email');
            localStorage.removeItem('swc-user-authLevel');
            setJwt('');
            setAuthLevel('');
            history.replace('/');
          } else if (res.status != 200) history.replace('/');
          return res.json();
        })
        .then((data) => {
          setGrps(data);
        })
        .catch((err) => console.log(err));
    } else if (authLevel === 'orgadmin') {
      fetch(`${process.env.REACT_APP_SWC_API_URL}/org`, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            localStorage.removeItem('swc-access-token');
            localStorage.removeItem('swc-user-email');
            localStorage.removeItem('swc-user-authLevel');
            setJwt('');
            setAuthLevel('');
            history.replace('/');
          } else if (res.status != 200) history.replace('/');
          return res.json();
        })
        .then((data) => {
          setGrps(data);
        })
        .catch((err) => console.log(err));
    } else {
      fetch(`${process.env.REACT_APP_SWC_API_URL}/user/groups`, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            localStorage.removeItem('swc-access-token');
            localStorage.removeItem('swc-user-email');
            localStorage.removeItem('swc-user-authLevel');
            setJwt('');
            setAuthLevel('');
            history.replace('/');
          } else if (res.status != 200) history.replace('/');
          return res.json();
        })
        .then((data) => {
          setGrps(data);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  function displayGrps() {
    if (!grps) return <></>;
    if (grps.length === 0) return <></>;
    return grps.map((grp) => {
      return (
        <div
          key={grp._id}
          onClick={() =>
            history.push({
              pathname: `/adminportal/${orgId}/${grp._id}`,
              state: {
                grpName: grp.name,
                orgName: props.location.state.orgName,
              },
            })
          }
          className={styles['item']}
        >
          <p>{grp.name}</p>
        </div>
      );
    });
  }

  function displayTitle() {
    if (!props.location.state) return;
    return (
      <div className={styles['nav--bar']}>
        <span className={styles['not--active']} onClick={() => history.push(`/adminportal`)}>
          Admin Portal{' '}
        </span>
        <FontAwesomeIcon icon={faChevronRight} className={styles['fa-chevron-right']} />
        <span className={styles['active']}>{props.location.state.orgName}</span>
      </div>
    );
  }

  function displayMessage() {
    if (!grps) return;
    if (grps.length === 0)
      return (
        <div className={styles['empty--message']}>
          <p>There is no group in this organization yet</p>
        </div>
      );
  }

  return (
    <div className={styles['admin']}>
      <div className={styles['header-container']}>
        <Header />
      </div>
      <div className={styles['admin--content']}>
        {displayTitle()}
        {displayMessage()}
        <div className={styles['admin--list']}>{displayGrps()}</div>
      </div>
    </div>
  );
}

export default AdminGrps;


