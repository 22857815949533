import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { getGroups } from '../services/getGroups';

export const useGroups = (jwt, setJwt) => {
  const history = useHistory();

  return useQuery({
    queryKey: ['groups'],
    queryFn: () => getGroups(jwt),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    onError: (err) => {
      if (err.message === 'Unauthorized') {
        setJwt('');
        history.push('/');
      } else {
        console.log(err);
      }
    },
  });
};
