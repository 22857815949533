import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import CodeBlock from './CodeBlock';

import styles from '../styles/Note.module.css';

function Note(markdownText) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={`${!isMobile && styles['use-custom-scrollbar']} ${styles.note}`}>
      <ReactMarkdown
        children={markdownText.markdownText}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSlug]}
        components={{
          code(props) {
            const { children, className } = props;
            const language = /language-(\w+)/.exec(className || '');

            return (
              <CodeBlock
                code={children}
                style={language ? 'markdown' : 'line'}
                language={language ? language : ''}
                copyOption={language}
              />
            );
          },
        }}
      />
    </div>
  );
}

export default Note;
