import React from 'react';
import { FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';

import styles from '../styles/NotificationPortal.module.css';

const NotificationTypeFilter = ({ filterType, setFilterType, messageType }) => (
  <FormControl component="fieldset" margin="normal">
    <FormLabel className={styles.inputLabel} component="legend">Show Notifications</FormLabel>
    <RadioGroup
      row
      aria-label="filterType"
      name="filterType"
      value={filterType}
      onChange={(e) => setFilterType(e.target.value)}
    >
      <FormControlLabel value={messageType === "feedback" ? "unresolved" : "unseen"} control={<Radio />} label={messageType === "feedback" ? "Unresolved Feedback" : "Unseen Notifications"} />
      <FormControlLabel value={messageType === "feedback" ? "resolved" : "seen"} control={<Radio />} label={messageType === "feedback" ? "Resolved Feedback" : "Seen Notifications"} />
    </RadioGroup>
  </FormControl>
);

export default NotificationTypeFilter;
