import React from 'react';

import { useJoinSiteModal } from '../providers/JoinSiteModalProvider';
import { Typography } from '@mui/material';

import BackgroundDoodle from '../../shared/BackgroundDoodle';
import { heroDoodle } from '../../../config/doodleMapper';

import styles from '../styles/Hero.module.css';

const Hero = () => {
  const { openModal } = useJoinSiteModal();

  return (
    <div className={styles['hero-container']}>
      <BackgroundDoodle styles={styles.doodle} doodle={heroDoodle} />
      <div className={styles['hero-content']}>
        <Typography sx={{ fontWeight: 500 }} variant="h5" gutterBottom>
          Let's Empower Tomorrow's Innovators to Make Their Dreams a Reality, Together
        </Typography>
        <div className={styles['hero-buttons']}>
          <button onClick={openModal} className={styles['join-button']}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
