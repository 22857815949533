import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';

import styles from '../styles/Quiz.module.css';

function QuizResult({ score, currAttemptScore, questions, answers, quizComplete, quizCompleteTime, retook }) {
  const { lessonId } = useParams();
  const { jwt } = useApplicationContext();
  const history = useHistory();

  function renderQuestions() {
    return questions.map((question, index) => {
      return (
        <div key={index}>
          <div className={styles['quiz-result-question']}>
            {isCorrect(question, index) ? (
              <FontAwesomeIcon className={styles['fa-circle-check']} icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon className={styles['fa-circle-xmark']} icon={faXmarkCircle} />
            )}
            Question {index + 1}: {question.question}
          </div>
          <div className={styles['quiz-result-answers']}>{renderAnswers(index)}</div>
          <div className={styles['quiz-result-explanation']}>Explanation: {question.explanation}</div>
        </div>
      );
    });
  }

  function isCorrect(question, questionNumber) {
    for (let index = 0; index < question.answers.length; index++) {
      if (question.answers[index].correct && !answers[questionNumber].answers.includes(index)) {
        return false;
      }
    }
    return true;
  }

  function renderAnswers(index) {
    const currentQuestion = questions[index];
    const chosenAnswers = answers[index];

    return currentQuestion.answers.map((answer, indexOfAnswer) => {
      let classList = '';
      let wasSelected = chosenAnswers.answers.includes(indexOfAnswer);
      let correct = answer.correct;

      if (correct) {
        classList = ' ' + styles['quiz-result-correct'];
      } else if (!correct && wasSelected) {
        classList = ' ' + styles['quiz-result-incorrect'];
      }
      return (
        <div key={indexOfAnswer} className={styles['quiz-result-answer'] + classList}>
          {answer.answerChoice}
        </div>
      );
    });
  }

  function retake() {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwt },
      body: JSON.stringify({ lessonId: lessonId }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/user/retakequiz`, requestOptions)
      .then((res) => {
        if (res.status === 200) history.go(0);
        else console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className={styles['quiz-result']}>
      <div className={styles['quiz-result-score']}>Best Grade: {score}</div>
      <div className={styles['quiz-result-score']}>Current Attempt Grade: {currAttemptScore}</div>
      <div className={styles['quiz-result-score']}>Let's go through the quiz together!</div>
      {renderQuestions()}
      {new Date() - new Date(quizCompleteTime) > 86400000 && !retook ? (
        <button className={styles['retake-button']} onClick={() => retake()}>
          Retake
        </button>
      ) : (
        ''
      )}
      {new Date() - new Date(quizCompleteTime) < 86400000 && !retook ? (
        <div className={styles['quiz-result-score']}>
          You can retake the quiz once 24 hours after the first attempt.
        </div>
      ) : (
        ''
      )}
      {retook ? <div className={styles['quiz-result-score']}>You already retook the quiz.</div> : ''}
    </div>
  );
}

export default QuizResult;
