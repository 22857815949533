import React, { useEffect } from 'react';

import Header from '../shared/header/Header';
import { useGroups } from '../../hooks/useGroups';
import { useApplicationContext } from '../../contexts/ApplicationContextProvider';
import { groupsDoodle } from '../../config/doodleMapper';
import BackgroundDoodle from '../shared/BackgroundDoodle';
import Group from './components/Group';

import styles from './styles/Groups.module.css';

const Groups = () => {
  const { jwt, setJwt, groups, setGroups } = useApplicationContext();
  const { data, error, isLoading } = useGroups(jwt, setJwt);
  const firstRender = groups.length === 0;
  const isDataReady = !isLoading && !error;

  useEffect(() => {
    if (firstRender && isDataReady) {
      setGroups(data.groups);
    } else if (!firstRender) {
      setGroups((prevGroups) => [...prevGroups]);
    }
  }, [data, isDataReady, setGroups, firstRender]);
  

  const displayGroups = () => groups.map((group) => <Group groupDetail={group} />);

  return (
    <div className={styles.courses}>
      <BackgroundDoodle styles={styles.doodle} doodle={groupsDoodle} />
      <div className={styles['courses--content']}>
        <Header />
        <div className={styles['courses--courselist']}>{!firstRender && isDataReady && displayGroups()}</div>
      </div>
    </div>
  );
};

export default Groups;
