import React from 'react';

import Spline from '@splinetool/react-spline';

import styles from '../styles/Animated3DModel.module.css';

const Animated3DModel = ({setLoading}) => (
  <div className={styles['model_3d_container']}>
    <Spline onLoad={ () => setLoading(false) } className={styles['model_3d']} scene="https://prod.spline.design/wpqME4o6hrGUAHLv/scene.splinecode" />
  </div>
);

export default Animated3DModel; 