import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import routes from './config/routes';
import protectedRoutes from './config/protectedRoutes';
import { useApplicationContext } from './contexts/ApplicationContextProvider';

import Toast from './pages/shared/toast/Toast';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  const { jwt: jwtValue, toast: toastValue } = useApplicationContext();
  const appRoutes = jwtValue ? protectedRoutes : routes;
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          {appRoutes.map((route) => (
            <Route exact key={route.path} path={route.path} component={route.component} />
          ))}
        </Switch>
        {toastValue && toastValue !== null && <Toast />}
      </Router>
    </QueryClientProvider>
  );
}
export default App;
