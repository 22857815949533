import React from 'react';
import ReactDOM from 'react-dom';

import { ApplicationContextProvider } from './contexts/ApplicationContextProvider';
import App from './App';

ReactDOM.render(
  <ApplicationContextProvider>
    <App />
  </ApplicationContextProvider>,
  document.getElementById('root')
);
