import React, { useState, forwardRef, useEffect } from 'react';
import UpVoteButton from './UpvoteButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';
import AsyncCodeFormatter from './AsyncCodeFormatter';
import { timeSince } from '../utils/time';

import styles from '../styles/Comments.module.css';

const Reply = forwardRef(({ reply, updateComment, parentCommentId }, ref) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { jwt } = useApplicationContext();

  const date = new Date(reply.createdAt);

  function fakeDeleteComment(commentId) {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwt,
      },
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/comment/fakedelete/${commentId}`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          updateComment('deleteReply', commentId, parentCommentId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function displayConfirmBox() {
    return (
      <div className={styles['confirm--box--container']} onClick={() => setShowDeleteConfirm(false)}>
        <div className={styles['confirm--box']}>
          <div>Delete your comment?</div>
          <button onClick={() => setShowDeleteConfirm(false)}>Cancel</button>
          <button onClick={() => fakeDeleteComment(reply._id)}>Delete</button>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            if (ref.current) ref.current.classList.add(styles.highlight);
            setTimeout(() => {
              if (ref.current) ref.current.classList.remove(styles.highlight);
            }, 1000);
            observer.disconnect();
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.5,
        }
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }
  }, []);

  return (
    <div className={`${styles.reply}`} ref={ref}>
      <div className={styles['comment--header']}>
        <div>
          <span className={styles['comment--name']}>{`${reply.userId.fname} ${reply.userId.lname}`}</span>
          {reply.userId.authLevel != 'student' ? (
            <span
              className={
                reply.userId.authLevel === 'teacher'
                  ? styles['comment--role'] + ' ' + styles['teacher']
                  : reply.userId.authLevel === 'orgadmin'
                    ? styles['comment--role'] + ' ' + styles['orgadmin']
                    : styles['comment--role'] + ' ' + styles['swcadmin']
              }
            >
              {reply.userId.authLevel === 'teacher' ? 'Teacher' : ''}
              {reply.userId.authLevel === 'orgadmin' ? 'Org Admin' : ''}
              {reply.userId.authLevel === 'swcadmin' ? 'SWC Admin' : ''}
            </span>
          ) : (
            ''
          )}
          <span className={styles['comment--date']}>{timeSince(date)}</span>
        </div>
        {reply.isOwner ? (
          <div className={styles['reply--delete']}>
            <FontAwesomeIcon onClick={() => setShowDeleteConfirm(true)} icon={faTrashCan} />
          </div>
        ) : (
          ''
        )}
        {showDeleteConfirm ? displayConfirmBox() : ''}
      </div>
      <div className={styles['comment--content']}>
        {reply.content.map(({ _id, type, content, language }) => (
          <div key={_id}>{type === 'code' ? <AsyncCodeFormatter code={content} language={language} /> : content}</div>
        ))}
      </div>
      <div className={styles['comment--footer']}>
        <UpVoteButton
          key={reply._id}
          hasUpvoted={reply.hasUpvoted}
          upvoteCount={reply.upVotes.length}
          commentId={reply._id}
          updateComment={updateComment}
        ></UpVoteButton>
      </div>
    </div>
  );
});

export default Reply;
