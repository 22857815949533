import React, { useState} from 'react';
import Modal from '@mui/material/Modal';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';
import { BUDDY1 } from '../../../constants/images';


import styles from '../styles/Lesson.module.css';

const Buddy = () => {
    const [modal, setModel] = useState(false);
    const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const { jwt } = useApplicationContext();

    const openModal = () => {
        setModel(true);
      };
    
      const closeModal = () => {
        setModel(false);
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        setAnswer('Buddy is thinking...');
        getAnswer();
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': jwt,
        },
        body: JSON.stringify({ question: question }),
      };

      const getAnswer = () => {
        fetch(`${process.env.REACT_APP_SWC_API_URL}/external/answer`, requestOptions)
          .then(async (response) => {
            if (response.ok) {
              let data = await response.json();
    
              setAnswer(data.answer);
              setQuestion('');
            } else if (response.status === 404 || response.status === 401) {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };

    return (
        <>
        <div className={styles['buddy__icon__container']} onClick={() => openModal()}>
            <img className={styles['buddy__icon']} src={BUDDY1} />
          </div>
        <div className={styles['buddy__helpertext']}>
            <p>I'm Buddy!</p>
            <p>Click on me to ask a question</p>
          </div>
          <Modal open={modal} onClose={closeModal}>
            <div className={styles['buddy__container']}>
              <div className={styles['buddy__container__input']}>
                <h1>It's me, Buddy! 😊 Ask me anything</h1>
                <textarea
                  placeholder="Write your question here"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <button className={styles['buddy__submit']} onClick={handleSubmit}>
                  Submit
                </button>
              </div>
              <div>
                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]}>
                  {answer}
                </ReactMarkdown>
              </div>
            </div>
          </Modal>
        </>
    )
};

export default Buddy;