import React from 'react';

import { Typography } from '@mui/material';

import { HOMEPAGE_MISSION } from '../../../constants/images';

import styles from '../styles/MissionStatement.module.css';

const MissionStatement = () => (
  <div className={styles.missionStatementContainer}>
    <Typography className={styles.missionTitle} variant="h4" sx={{ fontWeight: 600, padding: '1rem' }}>
      Our Mission
    </Typography>
    <img src={HOMEPAGE_MISSION} alt='' className={styles.misisonImage} />
    <Typography className={styles.missionText} sx={{margin: '2rem'}} variant="h6">
    We aim to ignite a passion for technology in <b className={styles.missionImportant}>K-12 students</b> by providing an immersive and collaborative coding education. <br />
    </Typography>

    <Typography className={styles.missionText} variant="h6" gutterBottom>
    Through our expertly crafted <b className={styles.missionImportant}>courses</b>, we empower students to transform their ideas into reality, using <b className={styles.missionImportant}>interactive videos</b>, <b className={styles.missionImportant}>quizzes</b>, and <b className={styles.missionImportant}>real-time support</b> to guide them every step of the way.
    </Typography>
  </div>
);

export default MissionStatement;
