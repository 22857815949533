import Home from '../pages/home/Home';
import Login from '../pages/login/Login';


const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/login',
        component: Login,
    },

];

export default routes;
