import { useQuery } from '@tanstack/react-query';
import { getNotifications } from '../services/getNotificiations';

export const useNotifications = (jwt) => {

  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => getNotifications(jwt),
    refetchInterval: 5000,
    onError: (err) => {
      console.log(err);
    },
  });
};
