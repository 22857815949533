import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { getLesson } from '../services/getLesson';

export const useLesson = (jwt, setJwt, lessonId, groupId,) => {
  const history = useHistory();

  return useQuery({
    queryKey: [`lesson-${lessonId}`],
    queryFn: () => getLesson(jwt, lessonId, groupId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    refetchOnMount: 'always',
    onError: (err) => {
      if (err.message === 'Unauthorized') {
        setJwt('');
        history.push('/');
      } else {
        console.log(err);
      }
    }
  });
};
