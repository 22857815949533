import { headers } from './headers';

export const getGroups = async (jwt) => {
  const serviceHeaders = headers(jwt);

  const response = await fetch(`${process.env.REACT_APP_SWC_API_URL}/group`, serviceHeaders);

  if (response.status === 401) {
    localStorage.removeItem('swc-access-token');
    localStorage.removeItem('swc-user-email');
    throw new Error('Unauthorized');
  }

  return response.json();
};
