import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Header from '../shared/header/Header';

import adminStyles from './styles/AdminPortal.module.css';
import circleStyles from './styles/Circle.module.css';

function AdminUser(props) {
  let { orgId, grpId, userId } = useParams();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState('videos');
  const setTabToVideos = () => {
    setCurrentTab('videos');
  };
  const setTabToQuizzes = () => {
    setCurrentTab('quizzes');
  };

  if (!props.location.state) {
    history.replace({
      pathname: `/adminportal`,
      state: {
        orgId: orgId,
        grpId: grpId,
        userId: userId,
      },
    });
  }

  function displayUser() {
    if (!props.location.state) return;
    if (!props.location.state.user) return;
    return (
      <div className={adminStyles['info--list']}>
        <table>
          <tr>
            <td className={adminStyles['key'] + ' ' + adminStyles['info']}>Name:</td>
            <td
              className={adminStyles['value'] + ' ' + adminStyles['info']}
            >{`${props.location.state.user.fname} ${props.location.state.user.lname}`}</td>
          </tr>
          <tr>
            <td className={adminStyles['key'] + ' ' + adminStyles['info']}>Email:</td>
            <td className={adminStyles['value'] + ' ' + adminStyles['info']}>{props.location.state.user.email}</td>
          </tr>
        </table>
      </div>
    );
  }

  function displayStats() {
    if (!props.location.state) return;
    if (!props.location.state.user) return;
    const videoStats = props.location.state.user.lessonStats.filter(
      (lessonStat) =>
        lessonStat.percWatched && props.location.state.lessons.find((lesson) => lesson._id === lessonStat.lesson)
    );
    const quizStats = props.location.state.user.lessonStats.filter(
      (lessonStat) =>
        lessonStat.score && props.location.state.lessons.find((lesson) => lesson._id === lessonStat.lesson)
    );
    const videosAverage = Math.round(
      videoStats.reduce((sum, videoStat) => videoStat.percWatched + sum, 0) / videoStats.length
    );
    const quizzesAverage = Math.round(quizStats.reduce((sum, quizStat) => quizStat.score + sum, 0) / quizStats.length);
    return (
      <div className={adminStyles['tabs--view']}>
        <div className={adminStyles['tabs']}>
          <button
            className={
              currentTab === 'videos' ? adminStyles['tab--btn'] + ' ' + adminStyles['active'] : adminStyles['tab--btn']
            }
            onClick={setTabToVideos}
          >
            Videos
          </button>
          <button
            className={
              currentTab === 'quizzes' ? adminStyles['tab--btn'] + ' ' + adminStyles['active'] : adminStyles['tab--btn']
            }
            onClick={setTabToQuizzes}
          >
            Quizzes
          </button>
        </div>
        <div className={adminStyles['tab--content--wrapper']}>
          <div
            className={
              currentTab === 'videos'
                ? adminStyles['tab--content'] + ' ' + adminStyles['active']
                : adminStyles['tab--content']
            }
          >
            <div className={adminStyles['tab--average']}>
              <div className={`${circleStyles.c100} ${circleStyles[`p${videosAverage}`]} ${circleStyles.green}`}>
                <span>{videosAverage ? videosAverage : 0}%</span>
                <div className={circleStyles['slice']}>
                  <div className={circleStyles['bar']}></div>
                  <div className={circleStyles['fill']}></div>
                </div>
              </div>
              <div className={adminStyles['average']}>Average</div>
            </div>
            <div className={adminStyles['tab--lessonStats']}>
              {videoStats.map((videoStat) => {
                return props.location.state.lessons.find((lesson) => lesson._id === videoStat.lesson) ? (
                  <div className={adminStyles['tab--lessonStat']}>
                    {props.location.state.lessons.find((lesson) => lesson._id === videoStat.lesson).name} -{' '}
                    {videoStat.percWatched}%
                  </div>
                ) : (
                  ''
                );
              })}
            </div>
          </div>
          <div
            className={
              currentTab === 'quizzes'
                ? adminStyles['tab--content'] + ' ' + adminStyles['active']
                : adminStyles['tab--content']
            }
          >
            <div className={adminStyles['tab--average']}>
              <div className={`${circleStyles.c100} ${circleStyles[`p${quizzesAverage}`]} ${circleStyles.green}`}>
                <span>{quizzesAverage ? quizzesAverage : 0}%</span>
                <div className={circleStyles['slice']}>
                  <div className={circleStyles['bar']}></div>
                  <div className={circleStyles['fill']}></div>
                </div>
              </div>
              <div className={adminStyles['average']}>Average</div>
            </div>
            <div className={adminStyles['tab--lessonStats']}>
              {quizStats.map((quizStat) => {
                return (
                  <div className={adminStyles['tab--lessonStat']}>
                    {props.location.state.lessons.find((lesson) => lesson._id === quizStat.lesson).name} -{' '}
                    {quizStat.score}%
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function displayTitle() {
    if (!props.location.state) return;
    return (
      <div className={adminStyles['nav--bar']}>
        <span className={adminStyles['not--active']} onClick={() => history.push(`/adminportal`)}>
          Admin Portal{' '}
        </span>
        <FontAwesomeIcon icon={faChevronRight} className={adminStyles['fa-chevron-right']} />
        <span
          onClick={() =>
            history.push({
              pathname: `/adminportal/${orgId}`,
              state: { orgName: props.location.state.orgName },
            })
          }
          className={adminStyles['not--active']}
        >
          {props.location.state.orgName}
        </span>
        <FontAwesomeIcon icon={faChevronRight} className={adminStyles['fa-chevron-right']} />
        <span
          onClick={() =>
            history.push({
              pathname: `/adminportal/${orgId}`,
              state: {
                orgName: props.location.state.orgName,
                grpName: props.location.state.grpName,
              },
            })
          }
          className={adminStyles['not--active']}
        >
          {props.location.state.grpName}
        </span>
        <FontAwesomeIcon icon={faChevronRight} className={adminStyles['fa-chevron-right']} />
        <span
          className={adminStyles['active']}
        >{`${props.location.state.user.fname} ${props.location.state.user.lname}`}</span>
      </div>
    );
  }

  return (
    <div className={adminStyles.admin}>
      <div className={adminStyles['header-container']}>
        <Header />
      </div>
      <div className={adminStyles['admin--content']}>
        {displayTitle()}
        {displayUser()}
        {displayStats()}
      </div>
    </div>
  );
}

export default AdminUser;
