import React from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';

import styles from '../styles/NotificationPortal.module.css';

import Notification from './Notification';

const NotificationList = ({ filteredNotifications, filterType }) => (
  <Box>
    <List className={styles.list}>
      {filteredNotifications.length > 0 ? (
        filteredNotifications.map((notification) => <Notification notification={notification} />)
      ) : (
        <ListItem>
          <ListItemText primary={`No ${filterType} notifications.`} />
        </ListItem>
      )}
    </List>
  </Box>
);

export default NotificationList;
