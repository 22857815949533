import { headers } from './headers';

export const getNotifications = async (jwt) => {
  const serviceHeaders = headers(jwt);

  const response = await fetch(`${process.env.REACT_APP_SWC_API_URL}/notification`, serviceHeaders);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};
