import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import QuizStart from './components/QuizStart';
import QuizQuestion from './components/QuizQuestion';
import QuizResult from './components/QuizResult';
import { useApplicationContext } from '../../contexts/ApplicationContextProvider';

import styles from './styles/Quiz.module.css';

function Quiz({ quizDetails }) {
  let { groupId, lessonId } = useParams();

  const [started, setStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [answers, setAnswers] = useState(quizDetails.quizAnswers);
  const [quizScore, setQuizScore] = useState(quizDetails.score);
  const [completeTime, setCompleteTime] = useState(quizDetails.quizCompleteTime);

  const answersRef = useRef(answers);

  const { jwt, authLevel, updateQuizProgress } = useApplicationContext();

  const quiz = quizDetails?.lesson?.quiz;
  
  function quizStatus() {
    // user comes back to the quiz
    if (answers.length && answers.length !== quiz.length && !started) {
      setStarted(true);
      setCurrentQuestion(quiz[answers.length]);
    }

    if (answers.length < quiz.length && started) {
      return 'continue';
    }
    else if (answers.length === 0) {
      return 'start';
    } 
    else if (answers.length === quiz.length) {
      return 'end';
    }
  }

  function startQuiz() {
    setStarted(true);
    setCurrentQuestion(quiz[0]);
  }

  function nextQuestion(submittedAnswer) {
    let updatedAnswers = answers.slice();
    let currentIndex = quiz.indexOf(currentQuestion);
    updatedAnswers[currentIndex] = {
      questionId: quiz[currentIndex]._id,
      answers: submittedAnswer,
    };
    setAnswers(updatedAnswers);

    if (currentIndex + 1 !== quiz.length) {
      updateQuizDetails(updatedAnswers);
      setCurrentQuestion(quiz[currentIndex + 1]);
    } else if (currentIndex + 1 === quiz.length) {
      updateQuizDetails(updatedAnswers);
    }
  }

  function updateQuizDetails(selectedAnswers) {
    let quizAnswers = [];
    let quizComplete = false;
    let score = 0;
    let quizCompleteTime;

    quizAnswers = selectedAnswers;
    quizComplete = quizAnswers.length === quiz.length;
    if (quizComplete) {
      quizCompleteTime = new Date();
      setCompleteTime(quizCompleteTime);
    }
    score = quizComplete ? calculateCorrectAnswers(quizAnswers) : 0;

    updateQuizProgress(groupId, lessonId, quizAnswers, quizComplete, quizCompleteTime, score, quizScore, quizDetails);

    if (authLevel !== 'swcadmin' && quizDetails.lesson._id === lessonId) {
      const requestOptions = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': jwt,
        },
        body: JSON.stringify({
          groupId: groupId,
          lessonId: lessonId,
          lessonType: 'quiz',
          quizAnswers: JSON.stringify(quizAnswers),
          quizComplete: quizComplete,
          ...(quizComplete ? { quizCompleteTime: quizCompleteTime } : {}),
          score: score,
        }),
      };
      fetch(`${process.env.REACT_APP_SWC_API_URL}/user/updatestats`, requestOptions).then((response) => {
        return;
      });
    }

    if (score > quizScore) setQuizScore(score);
  }

  function calculateCorrectAnswers(selectedAnswers) {
    let score = 0;

    quiz.forEach((question, index) => {
      let correct = true;
      question.answers.forEach((answer, indexOfAnswer) => {
        if (answer.correct && !selectedAnswers[index].answers.includes(indexOfAnswer)) {
          correct = false;
        }
      });
      if (correct) {
        score++;
      }
    });

    return (score / quiz.length) * 100;
  }

  useEffect(() => {
    answersRef.current = answers;
  }, [answers]);

  useEffect(() => {
    return () => {
      if (quizDetails.lesson._id !== lessonId) {
        updateQuizDetails(answersRef.current);
      }
    };
  }, [lessonId, updateQuizDetails]);

  return (
    <div className={styles.quiz}>
      {quizStatus() === 'start' ? (
        <QuizStart
          name={quizDetails.lesson.name}
          details={quizDetails.lesson.description}
          onClick={() => startQuiz()}
        />
      ) : quizStatus() === 'continue' ? (
        <QuizQuestion
          index={quiz.indexOf(currentQuestion) + 1}
          numberOfQuestions={quiz.length}
          question={currentQuestion}
          nextQuestion={
            quiz.indexOf(currentQuestion) + 1 !== quiz.length ? quiz[quiz.indexOf(currentQuestion) + 1] : {}
          }
          onClick={(submittedAnswer) => nextQuestion(submittedAnswer)}
        />
      ) : quizStatus() === 'end' ? (
        <QuizResult
          score={quizScore}
          currAttemptScore={calculateCorrectAnswers(answers)}
          questions={quiz}
          answers={answers}
          quizCompleteTime={completeTime}
          retook={quizDetails.retook}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default Quiz;
