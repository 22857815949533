import { headers } from './headers';

export const getLesson = async (jwt, lessonId, groupId) => {
  const serviceHeaders = headers(jwt);

  const response = await fetch(`${process.env.REACT_APP_SWC_API_URL}/lesson/` + lessonId + '/group/' + groupId, serviceHeaders);

  if (response.status === 404) {
    localStorage.removeItem('swc-access-token');
    localStorage.removeItem('swc-user-email');
    throw new Error('Unauthorized');
  }

  return response.json();
};
