import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';

import { useApplicationContext } from '../../../contexts/ApplicationContextProvider';
import { useNotifications } from '../../../hooks/useNotifications';
import GroupFilter from './GroupFilter';
import NotificationTypeFilter from './NotificationTypeFilter';
import NotificationList from './NotificationList';
import SortFilter from './SortFilter';
import LessonFilter from './LessonFilter';

import styles from '../styles/NotificationPortal.module.css';

const filterNotifications = (notifications, selectedGroup, selectedLesson) => {
  return notifications.filter((notification) => {
    const matchesGroup = !selectedGroup || notification.groupId.name === selectedGroup;
    const matchesLesson = !selectedLesson || notification.lessonId.name === selectedLesson;
    return matchesGroup && matchesLesson;
  });
};

const sortNotifications = (notifications, sortOrder) => {
  return notifications.sort((a, b) =>
    sortOrder === 'newest'
      ? new Date(b.createdAt) - new Date(a.createdAt)
      : new Date(a.createdAt) - new Date(b.createdAt)
  );
};

const Notifications = () => {
  const { jwt } = useApplicationContext();
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');
  const [filterType, setFilterType] = useState('unseen');
  const [sortOrder, setSortOrder] = useState('newest');

  const { data, isLoading, isError } = useNotifications(jwt);
  const isDataReady = !isLoading && !isError;

  if (!isDataReady) {
    return <></>;
  }

  const { seen, unseen } = data;

  const groupIds = [...new Set([...seen, ...unseen].map((notification) => notification.groupId.name))];
  const lessonIds = [...new Set([...seen, ...unseen].map((notification) => notification.lessonId.name))];

  const notificationsByType = filterType === 'unseen' ? unseen : seen;
  const filteredNotifications = filterNotifications(notificationsByType, selectedGroup, selectedLesson);
  const sortedNotifications = sortNotifications(filteredNotifications, sortOrder);

  return (
    <Container className={styles.container}>
      <Typography variant="h4" sx={{ fontWeight: 600, fontSize: '2rem' }} gutterBottom>
        Notifications
      </Typography>
      <GroupFilter selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} groupIds={groupIds} />
      <LessonFilter selectedLesson={selectedLesson} setSelectedLesson={setSelectedLesson} lessonIds={lessonIds} />
      <NotificationTypeFilter filterType={filterType} setFilterType={setFilterType} />
      <SortFilter sortOrder={sortOrder} setSortOrder={setSortOrder} />
      <NotificationList filteredNotifications={sortedNotifications} filterType={filterType} />
    </Container>
  );
};

export default Notifications;