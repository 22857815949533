import { STUDENTPROJECT_JEANELLE } from '../../../constants/images';
import { STUDENTPROJECT_JENUSHAN } from '../../../constants/images';
import { STUDENTPROJECT_MARYAMA } from '../../../constants/images';

export const ProjectsMapper = [
  {
    title: "Jeanelle's Hobbies & Music",
    image: STUDENTPROJECT_JEANELLE,
    description:
      'This project showcases Jeanelle’s creativity as she implements lots of animations an styling while telling us about her favorites foods, music, and more! The "Favorite Foods" section highlights her culinary favorites, complete with clickable images for recipes. Additionally, the "Favorite Artists" page reflects her musical tastes, featuring artist photos and links to profiles. This project demonstrates Janelle’s proficiency in HTML and CSS, creative design sense, and ability to organize content effectively.',
    color: '#caafef',
  },
  {
    title: "Maryama's Religion and Fashion",
    image: STUDENTPROJECT_MARYAMA,
    description:
      "The \"Muslim Fashion\" project educates viewers about traditional and modern Muslim clothing. The women's section introduces the abaya, a long, loose dress, detailing its evolution and cultural significance in promoting modesty. The men's section highlights the kufi, a traditional hat, explaining its variations and religious importance. The project showcases the student's skills in web design and cultural research, resulting in a visually appealing and informative resource.",
    color: '#ff80a0',
  },
  {
    title: "Jenushan's Vegetarian Cookbook",
    image: STUDENTPROJECT_JENUSHAN,
    description:
      'The "Cookbook" project showcases a student-created website dedicated to sharing favorite recipes, with a special focus on Roti and Eggplant Curry. The site includes detailed instructions, ingredients list, and cooking tips, reflecting the student\'s passion for culinary arts. This project highlights the student\'s skills in web design and content creation, offering an engaging and practical resource for cooking enthusiasts.',
    color: '#aadbff',
  },
];
