import React, { useState } from 'react';

import { CssBaseline } from '@mui/material';
import { Container } from '@mui/system';

import { JoinSiteModalProvider } from './providers/JoinSiteModalProvider';
import Header from './components/Header';
import Animated3DModel from './components/Animated3DModel';
import Hero from './components/Hero';
import CreateSection from './components/CreateSection';
import MissionStatement from './components/MissionStatement';
import Platform from './components/Platform';
import Partners from './components/Partners';
import StudentProjects from './components/StudentProjects';
import Footer from '../shared/footer/Footer';

import styles from './styles/Home.module.css';

const HeroSection = () => {
  const [loading, setLoading] = useState(true);

  return (
    <JoinSiteModalProvider>
      <Container className={styles['container']} maxWidth={false} disableGutters>
        <CssBaseline />
        <Container sx={{ paddingTop: '16px' }}>
          {!loading && <Header />}
          <Animated3DModel setLoading={setLoading} />
          {!loading && <Hero />}
          {!loading && <MissionStatement />}
          {!loading && <CreateSection />}
          {!loading && <Platform />}
          {!loading && <StudentProjects />}
          {!loading && <Partners />}
        </Container>
        {!loading && <Footer />}
      </Container>
    </JoinSiteModalProvider>
  );
};

export default HeroSection;
